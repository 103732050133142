import { AdminSidebar, Button, Header } from './components'
import { Outlet } from 'react-router-dom';
import { socketIo } from './socket';
import { useEffect, useState, createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from './store/categorySlice';
import { Modal } from './components'
import pulse from './assets/pulse.svg'
import { getBrands } from './store/productSlice';
import { io } from 'socket.io-client';
import { Signin } from './pages';
import { getUser } from './store/userSlice';

// const URL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:3001';
export let socketContext = createContext()

function App() {

  const dispatch = useDispatch()
  const showModal = useSelector(state => state.modal.showModal)
  const childrenModal = useSelector(state => state.modal.children)
  const loading = useSelector(state => state.utility.loading)
  const isExpand = useSelector(state => state.utility.isExpandSideBar)
  const isSignin = useSelector(state => state.user.is_signin)
  const [socket, setSocket] = useState(socketIo)

  useEffect(() => {
    dispatch(getCategories())
    dispatch(getBrands())
    const uid = localStorage.getItem('uid')
    dispatch(getUser(uid))
  }, [])


  useEffect(() => {
    socket.connect();
    return () => {
      socket.disconnect()
    }
  }
    , [socket])


  return (
    <div>
      {isSignin ? (
        <div>
          {showModal && <Modal>
            {childrenModal}
          </Modal>}

          {loading && <Modal>
            <img src={pulse}></img>
          </Modal>}
          <socketContext.Provider value={socket}>
            <div className={showModal ? `fixed` : ''}>
              <div className='fixed w-full z-30'>
                <Header />
              </div>
              <div className='flex w-full min-h-screen relative text-gray-900'>
                <div className='top-0 min-h-screen z-40 fixed'>
                  <AdminSidebar />
                </div>
                <div className={isExpand ? `w-[200px]` : `w-[80px]`}></div>
                <div className='flex-1 p-12 mt-[50px]'>
                  <Outlet />
                </div>
              </div>
            </div>
          </socketContext.Provider>
        </div>
      ) : (<Signin />)}

    </div>
  );
}

export default App;
