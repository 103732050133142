import axios from '../utils/axios'


const getOrders = (params) => axios({
  url: '/order',
  method: 'get',
  params
})

const getOrder = (id) => axios({
  url: '/order' + id,
  method: 'get',
})

const updateOrder = (data) => axios({
  url: '/order/' + data._id,
  method: 'put',
  data
})

const getPopularProducts = (params) => axios({
  url: '/order/popular-items',
  method: 'get',
  params
})

const getOrderStatus = (params) => axios({
  url: '/order/status',
  method: 'get',
  params
})



export default { getOrders, updateOrder, getOrder, getPopularProducts, getOrderStatus }
