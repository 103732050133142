import React, { useState, useEffect } from 'react'
import { io } from 'socket.io-client'
import './Chat.css'
import API from '../apis/index'
import chatImg from '../assets/chat.svg'
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons'

const URL = process.env.REACT_APP_SOCKET_URL;
const Chat = () => {
  const [message, setMessage] = useState('')
  const [currentMessages, setCurrentMessages] = useState([])
  const [currentUser, setCurrentUser] = useState(null)

  const [users, setUsers] = useState([])

  const [socket, setSocket] = useState(null)

  const [status, setStatus] = useState('ongoing')

  const handleReceiveNewMessage = (data) => {
    const { message, sender } = data

    if (currentUser?._id == data.sender) {
      console.log('new message')
      setCurrentMessages(prev => [...prev, data])

    }

    setUsers(prev => {
      const idx = prev.findIndex(user => user._id == sender)
      const updatedUsers = [...prev]
      updatedUsers[idx] = { ...prev[idx], lastMessage: message, unread: 1 }
      return updatedUsers
    })



  }

  useEffect(() => {
    console.log('user chat: ', users)
  }, [users])

  const sendMessage = () => {
    const data = {
      sender: 'admin',
      targetUser: currentUser?._id,
      message,
    }
    setCurrentMessages(prev => [...prev, data])
    socket.emit('new-message', JSON.stringify(data))
    setMessage('')
  }


  const handleEndSession = () => {
    Swal.fire({
      title: 'Kết thúc phiên hỗ trợ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Đồng ý'
    }).then((result) => {
      if (result.isConfirmed) {

        const data = {
          sender: 'admin',
          targetUser: currentUser?._id
        }
        socket.emit('end-session', JSON.stringify(data))


      }
    })

  }

  const fetchMessages = async () => {
    const messages = await API.ChatAPI.getMessages({ userId: currentUser?._id })
    setCurrentMessages(messages)
  }

  const fetchChats = async () => {
    const users = await API.ChatAPI.getChats()
    setUsers(users)
  }

  const handleDisconnectUser = async (data) => {

    // if (users.length == 0) return
    // console.log('users: ', users)
    // const idx = users.findIndex(user => user._id == data.user)
    // console.log('user discouunnect on idx: ', users[idx])

    Swal.fire({
      position: 'center',
      icon: 'info',
      title: 'Kết thúc phiên hỗ trợ',
      // text: `${users[idx]?.pronoun} ${users[idx]?.userName} STT ${idx + 1} đã kết thúc phiên hỗ trợ`,
      showConfirmButton: true,
      confirmButtonText: 'OK'
    })

    // if (users[idx]._id == currentUser._id) {

    // }

    await fetchChats()
    setCurrentUser(null)


  }

  useEffect(() => {
    const connected_socket = io(URL, { withCredentials: true });
    setSocket(connected_socket)

  }, [])


  useEffect(() => {
    if (socket) {
      socket.connect();
      socket.emit('join', JSON.stringify({ role: 'admin' }))
      socket.on('new-message', (data) => {
        console.log('new message arrive: ', data)
        handleReceiveNewMessage(data)
        console.log('current is ', currentUser)

        // if (currentUser?._id == data.sender) {
        //   setCurrentMessages(prev => [...prev, data])

        // }
      })
      socket.on('join', (data) => {
        let user = JSON.parse(data)
        console.log('new user: ', user)
        console.log('new user has joined: ', user._id)
        setUsers(prev => [...prev, user])
      })

      socket.on('user-disconnect', (data) => {
        console.log('all users: ', users)

        let data_json = JSON.parse(data)
        console.log('user disconnect data: ', data_json)
        handleDisconnectUser(data_json)
      })


      // socket.on('retrieve-messages', (data) => {
      //   console.log('retrieve data: ', data)
      // })



      return () => {
        socket.disconnect()
      }
    }
  }, [socket])


  useEffect(() => {
    if (currentUser) {
      fetchMessages()
    }
  }, [currentUser])

  useEffect(() => {
    fetchChats()
  }, [])

  // const fetchChatSession = async () => {
  //   const sessions = await API.ChatAPI.getSessions({ status })
  //   console.log('sessions: ', sessions)
  //   setUsers(sessions)
  // }
  // useEffect(() => {
  //   fetchChatSession()
  // }, [status])

  return (
    <div className='flex shadow-md rounded-md'>
      <div className='relative border bg-white w-[25%] h-[500px] overflow-scroll overflow-x-hidden'>

        <div className='bg-white p-4 absolute w-full h-[100px] shadow-lg rounded-md'>

          <input placeholder='Tìm kiếm' className='py-1 px-2 border-2 rounded-lg'></input>

          <div className='px-4 py-2 hover:bg-gray-100 cursor-pointer text-sm text-lg mt-3 text-center'>Hàng chờ <span className='text-red-600 font-semibold'>({users.length})</span></div>

        </div>

        <div className='mt-[100px]'>

          {
            users.map((user, idx) => (

              <div className='p-4 cursor-pointer border-b-2' onClick={() => {
                setCurrentUser(user)

              }}>
                <div className='flex justify-between'>

                  <div className='font-semibold mb-2'> {user?.pronoun}. {user?.userName} </div>
                  <div>STT: {idx + 1}</div>
                  {/* <div className='w-[20px] h-[20px] rounded-lg bg-red-500 flex justify-center items-center text-white'>{user.unread}</div> */}
                </div>
                <div className='text-xs'>{user?.lastMessage}</div>
              </div>
            ))
          }

        </div>


      </div>

      {currentUser ? (<div className='bg-slate-50 w-full'>
        <div className='bg-white p-4 border shadow-md text-xl font-semibold flex justify-between'>
          <div>{currentUser?.pronoun}. {currentUser?.userName}</div>
          <div className='bg-red-100 text-red-700 w-fit px-4 py-2 text-sm rounded cursor-pointer hover:bg-red-200' onClick={handleEndSession}>Kết thúc session</div>
        </div>
        <div id='message-container' className='h-[480px] overflow-scroll overflow-x-hidden px-4 py-2'>
          {currentMessages.map((item, idx) => (
            <div className={item.sender == 'admin' ? 'admin-message' : 'user-message'}>{item.message}</div>
          ))}
        </div>

        <div className='w-full flex'>
          <input onChange={(e) => { setMessage(e.target.value) }} className='w-full px-4 py-3' value={message}></input>
          <button className='w-[80px] bg-slate-400 text-white rounded-lg' onClick={sendMessage}><FontAwesomeIcon icon={faPaperPlane} /></button>
        </div>

      </div>) : (
        <div className='bg-slate-50 w-full flex items-center justify-center'>
          <img src={chatImg} className='w-[50%] h-[50%] text-white'></img>
        </div>
      )}
    </div>
  )
}

export default Chat