import React, { useState, useEffect } from 'react'
import { AddBtn, Button, UploadWidget } from '../components'
import { useSelector, useDispatch } from 'react-redux'
import { Editor } from '../components'
import { closeModal, showModal } from '../store/modalSlice'
import { extractAttributeValues, formatMoney } from '../utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import './AddProduct.css'
import API from '../apis/index'
import Swal from 'sweetalert2'

const VariantModal = ({ attributes, handleAddVariant }) => {
  const dispatch = useDispatch()
  const [variant, setVariant] = useState({ attributes: [], price: 0, stock: 0 })
  useEffect(() => {
    let variant_attributes = []
    attributes.forEach(item => {
      variant_attributes.push({ name: item, value: '' })
    })
    setVariant(prev => ({ ...prev, attributes: variant_attributes }))
  }, [])
  return (<div className='bg-white w-[40%] h-[70%] rounded-lg py-6 px-10'>
    <div className='font-semibold text-2xl mb-6 text-center'>Phân loại</div>

    <div className='mb-2'>Thuộc tính: </div>
    <div>
      <table>
        <thead>
          <tr>
            <th>Thuộc tính</th>
            <th>Giá trị</th>
          </tr>
        </thead>
        <tbody>
          {variant.attributes.map((item, idx) => (
            <tr>
              <td>{item.name}</td>
              <td><input className='w-full px-4' onChange={(e) => {
                let attributes = variant.attributes.map(spec => {
                  let value = spec.value
                  if (spec.name == item.name) {
                    value = e.target.value
                  }
                  return {
                    ...spec, value
                  }
                })

                setVariant(prev => ({ ...prev, attributes }))
              }}></input></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <div className='flex mt-6'>
      <span className='w-[15%]'>Giá</span><input type='number' className='input-text' min={0} onChange={
        (e) => {
          setVariant(prev => ({ ...variant, price: e.target.value }))
        }
      }></input>
    </div>
    <div className='flex mt-4'>
      <span className='w-[15%]'>Số lượng</span><input type='number' className='input-text' min={0} onChange={
        (e) => {
          setVariant(prev => ({ ...variant, stock: e.target.value }))
        }
      }></input>
    </div>

    <div className='flex justify-center gap-4 mt-6'>
      <Button handleClick={() => {
        handleAddVariant(variant)
        dispatch(closeModal())
        console.log('add variant: ', variant)
      }}>Thêm</Button>

      <Button style={'px-4 py-2 rounded-md text-white bg-red-600 text-semibold my-2'} handleClick={() => {
        dispatch(closeModal())
      }}>Hủy</Button>
    </div>

  </div>)
}

export const AddProduct = () => {

  const categories = useSelector(state => state.category.value)
  const brands = useSelector(state => state.product.brands)
  const [variants, setVariants] = useState([])
  const [newProduct, setNewProduct] = useState({})
  const dispatch = useDispatch()
  const [variantAttributes, setVariantAttributes] = useState([])
  const [newAttribute, setNewAttribute] = useState('')
  const [mainImg, setMainImg] = useState(null)
  const [images, setImages] = useState([])
  const [tags, setTags] = useState([])
  const [newTag, setNewTag] = useState('')


  const addVariant = (variant) => {
    setVariants(prev => [...prev, { ...variant }])

  }

  useEffect(() => {
    setNewProduct(prev => ({ ...prev, variants }))
  }, [variants])

  useEffect(() => {
    setNewProduct(prev => ({ ...prev, mainImg, images }))
  }, [mainImg, images])

  const handleAddNewProduct = async () => {
    //console.log('new product: ', newProduct)
    const product = await API.ProductAPI.addProduct(newProduct)
    console.log('new product: ', product)

    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Đã thêm sản phẩm thành công',
      showConfirmButton: false,
      timer: 1500
    })

    setNewProduct({})
    setMainImg('')
    setVariants([])
    setImages([])

  }

  return (
    <div>
      <div className='text-3xl mb-10'>Thêm sản phẩm</div>
      <div className='border-2 py-10 px-14 rounded-lg shadow-lg'>

        <div className='mb-6'>
          <label className='mr-4 text-lg'>Danh mục</label>
          <select id='category' className='border' onChange={((e) => {
            let category = categories.find(item => item.name == e.target.value)
            let tech_specs = []
            category.tech_specs?.forEach(item => {
              tech_specs.push({ name: item, value: '' })
            })
            setNewProduct(prev => ({ ...prev, category: e.target.value, tech_specs }))
          })}>
            {categories.map((item, idx) => (
              <option key={idx} idx={idx} value={item.name}>{item.name}</option>
            ))}
          </select>
        </div>

        <div className='flex'>
          <div className='mb-6 mr-20'>
            <label className='mr-4 text-lg'>Tên sản phẩm</label>
            <input type='text' className='input-text w-[450px]' onChange={(e) => {
              setNewProduct(prev => ({ ...prev, title: e.target.value }))
            }} />
          </div>

          <div className='mb-6'>
            <label className='mr-4 text-lg'>Thương hiệu</label>
            <select onChange={(e) => { setNewProduct(prev => ({ ...prev, brand: e.target.value })) }}>
              {brands.map((brand, idx) => (
                <option key={idx} value={brand}>{brand}</option>
              ))}
            </select>
          </div>
        </div>

        <div className='flex gap-10'>
          <div className='mb-6'>
            <label className='mr-4'>Thumbnail</label>
            <UploadWidget isMultiple={false} imgs={mainImg !== null ? [mainImg] : []} setImgs={setMainImg} />
          </div>

          <div className='mb-6'>
            <label className='mr-4'>Ảnh khác</label>
            <UploadWidget isMultiple={true} imgs={images} setImgs={setImages} />
          </div>
        </div>

        <div className='mb-6'>
          <div className='text-lg mb-3'>Biến thể</div>
          <div className='py-6 px-8 border rounded-lg shadow-md'>
            <div className='mb-8'>
              <div className='mb-4 flex items-center'>
                <div className='mr-4'>Thuộc tính: </div>
                <div className='flex items-center'>
                  <input className='input-text'
                    onChange={(e) => { setNewAttribute(e.target.value) }} value={newAttribute}></input>
                  <AddBtn handleOnClick={() => {
                    if (newAttribute == '') return
                    setVariantAttributes(prev => [...prev, newAttribute])
                    setNewAttribute('')
                  }} />
                </div>
              </div>
              <div className='flex gap-4 flex-wrap'>
                {variantAttributes.map((item, idx) => (
                  <div className='relative'>
                    <div className='px-4 py-2 bg-slate-300 rounded text-sm'>{item}</div>
                    <div className='absolute -right-1 -top-3 cursor-pointer' onClick={
                      () => {
                        setVariantAttributes(prev => prev.filter(attr => attr !== item))
                      }
                    }>
                      <FontAwesomeIcon icon={faCircleXmark} />
                    </div>
                  </div>
                ))}

              </div>


            </div>
            <hr className='border'></hr>
            <div className='mt-5'>
              <div className='mb-4'>Các phân loại</div>

              {variants.length > 0 && (<div>
                <table className='w-[700px] mb-3'>
                  <thead>
                    <tr>
                      <th>Phân loại</th>
                      <th>Giá bán</th>
                      <th>Tồn kho</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      variants.map((item, idx) => (
                        <tr>
                          <td>{extractAttributeValues(item.attributes) || 'Một phân loại'}</td>
                          <td>{(item.price)}</td>
                          <td>{item.stock}</td>
                        </tr>
                      ))
                    }
                  </tbody>

                </table>
              </div>)}

              <Button handleClick={() => {
                dispatch(showModal(<VariantModal attributes={variantAttributes} handleAddVariant={addVariant} />))
              }}>Thêm phân loại</Button>
            </div>
          </div>
        </div>


        {/* <div className='mb-6'>
          <div className='text-lg mb-3'>Tag</div>
          <div className='py-6 px-8 border rounded-lg shadow-md'>
            <div>
              <div className='mb-4 flex items-center'>
                <div className='mr-4'>Tag: </div>
                <div className='flex items-center'>
                  <input className='input-text'
                    onChange={(e) => { setNewTag(e.target.value) }} value={newTag}></input>
                  <AddBtn handleOnClick={() => {
                    if (newTag == '') return
                    setTags(prev => [...prev, newTag])
                    setNewTag('')
                  }} />
                </div>
              </div>
              <div className='flex gap-4 flex-wrap mt-8'>
                {tags.map((item, idx) => (
                  <div className='relative'>
                    <div className='px-4 py-2 bg-slate-300 rounded text-sm'>{item}</div>
                    <div className='absolute -right-1 -top-3 cursor-pointer' onClick={
                      () => {
                        setTags(prev => prev.filter(tag => tag !== item))
                      }
                    }>
                      <FontAwesomeIcon icon={faCircleXmark} />
                    </div>
                  </div>
                ))}

              </div>


            </div>
          </div>
        </div> */}

        <div className='mb-6'>
          <label className='mr-4 text-lg mb-3'>Mô tả</label>
          <Editor />
        </div>

        <div className='mb-6'>
          <div className='text-lg mb-4'>Thông số kĩ thuật</div>
          <table>
            <thead>
              <tr><th>Thông số</th>
                <th>Giá trị</th></tr>
            </thead>
            <tbody>
              {newProduct.tech_specs?.map((item, idx) => (
                <tr key={idx}>
                  <td>{item.name}</td>
                  <td><input type='text' placeholder='giá trị' className='w-full px-4' value={item.value}
                    onChange={(e) => {
                      let tech_specs = newProduct.tech_specs.map(spec => {
                        let value = spec.value
                        if (spec.name == item.name) {
                          value = e.target.value
                        }
                        return {
                          ...spec, value
                        }
                      })

                      setNewProduct(prev => ({ ...prev, tech_specs }))

                    }} /></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='flex justify-center'>
          <Button handleClick={handleAddNewProduct}><span>Thêm sản phẩm</span></Button>
        </div>
      </div>
    </div>
  )
}
