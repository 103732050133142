import React, { useEffect, useState } from 'react'
import './AdminSidebar.css'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine, faTags, faAnglesLeft, faAnglesRight, faHeadset, faPlus, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { faClipboard, faUser, faFolderClosed, faRectangleList } from '@fortawesome/free-regular-svg-icons'
import { faProductHunt } from '@fortawesome/free-brands-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { expandSideBar } from '../store/utilitySlice'
import { signOut } from '../store/userSlice'
export const AdminSidebar = () => {

  const isExpand = useSelector(state => state.utility.isExpandSideBar)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [tab, setTab] = useState(0)

  const handleLogout = () => {
    dispatch(signOut())
    navigate('/')

  }

  useEffect(() => {
    const product_menu = document.getElementById('product-menu')
    const product_sub_menu = document.getElementById('product-sub-menu')
    if (product_menu) {
      product_menu.addEventListener('click', () => {
        product_sub_menu.classList.toggle('hidden')
      })
    }

    // if (tab !== 2) {
    //   product_sub_menu.classList.add('hidden')
    // }

  }, [])


  return (
    <div>
      {
        isExpand ? (<div className='min-h-screen bg-primary w-[220px]'>
          <div className='flex flex-col justify-center pt-10 pb-10 text-white'>
            <div className='text-center'>BK-DIGITAL</div>
            <div className='text-center'>Admin workspace</div>
          </div>
          <hr></hr>
          <ul className='menu mt-5'>
            <li className={`${tab == 0 && 'bg-secondary'}`} onClick={() => setTab(0)}><Link to=''><FontAwesomeIcon icon={faChartLine} /> Dashboard</Link></li>

            <li className={`${tab == 1 && 'bg-secondary'}`} onClick={() => setTab(1)}><Link to='/categories'><FontAwesomeIcon icon={faClipboard} /> Danh mục</Link></li>

            <li className={`${tab == 2 && 'bg-secondary'}`} onClick={() => setTab(2)}>
              <div id='product-menu' className='cursor-pointer'> <span><FontAwesomeIcon icon={faProductHunt} /> Sản phẩm</span></div>
              <ul className='hidden -mx-10 mt-2' id='product-sub-menu'>
                <hr></hr>
                <li className='text-sm -ml-2'><Link to='/products'> <FontAwesomeIcon icon={faRectangleList} /> Danh sách sản phẩm</Link></li>
                <li className='text-sm -ml-2'> <Link to='/add_product'><FontAwesomeIcon icon={faPlus} />Thêm sản phẩm</Link></li>
              </ul>
            </li>
            <li className={`${tab == 3 && 'bg-secondary'}`} onClick={() => setTab(3)}><Link to='/orders'><FontAwesomeIcon icon={faFolderClosed} /> Đơn hàng</Link></li>
            <li className={`${tab == 4 && 'bg-secondary'}`} onClick={() => setTab(4)}><Link to='/discounts'><FontAwesomeIcon icon={faTags} /> Mã khuyến mãi</Link></li>
            <li className={`${tab == 5 && 'bg-secondary'}`} onClick={() => setTab(5)}><Link to='/users'> <FontAwesomeIcon icon={faUser} /> Người dùng</Link></li>
            <li className={`${tab == 6 && 'bg-secondary'}`} onClick={() => setTab(6)}><Link to='/chat'><FontAwesomeIcon icon={faHeadset} /> Chat hỗ trợ</Link></li>
          </ul>
          <div className='absolute bottom-0 text-white w-full text-center p-4'>
            {/* <div className='mb-2 cursor-pointer' onClick={handleLogout}>
              <FontAwesomeIcon icon={faPowerOff} /> Đăng xuất</div> */}

            <hr></hr>
            <div className='mt-2 cursor-pointer' onClick={() => dispatch(expandSideBar(false))}>
              <FontAwesomeIcon icon={faAnglesLeft} />
            </div>
          </div>
        </div>) : (<div className='min-h-screen bg-primary w-[80px] pt-10'>

          <ul className='menu mt-5'>
            <li><Link to=''><FontAwesomeIcon icon={faChartLine} /> </Link></li>

            <li><Link to='/categories'><FontAwesomeIcon icon={faClipboard} /> </Link></li>

            <li>
              <div id='product-menu' className='cursor-pointer'> <span><FontAwesomeIcon icon={faProductHunt} /></span></div>
              <ul className='hidden' id='product-sub-menu'>
                <li><Link to='/products'></Link></li>
                <li> <Link to='/add_product'></Link></li>
              </ul>
            </li>
            <li><Link to='/orders'><FontAwesomeIcon icon={faFolderClosed} /> </Link></li>
            <li><Link to='/discounts'><FontAwesomeIcon icon={faTags} /> </Link></li>
            <li><Link to='/users'> <FontAwesomeIcon icon={faUser} /> </Link></li>
            <li><Link to='/chat'><FontAwesomeIcon icon={faHeadset} /></Link></li>
          </ul>
          <div className='absolute bottom-0 text-white w-full text-center p-4'>
            <hr></hr>
            <div className='mt-2 cursor-pointer' onClick={() => dispatch(expandSideBar(true))}>
              <FontAwesomeIcon icon={faAnglesRight} />
            </div>
          </div>


        </div>)
      }


    </div>
  )
}
