import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API from '../apis/index'

//async thunk
export const getBrands = createAsyncThunk('product/getBrands',
  async () => {
    try {
      const response = await API.ProductAPI.getBrands()
      //check error code
      return response
    }
    catch (err) {
      console.log(err)
    }
  })


const initialState = {
  brands: [],
}

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(getBrands.pending, () => {
      console.log('loading brand')
    })
    builder.addCase(getBrands.fulfilled, (state, action) => {
      state.brands = action.payload.sort()
      console.log('brands: ', action.payload)

    })
    builder.addCase(getBrands.rejected, (state, action) => {
      console.log('brands rejected')
    })
  }
})

// Action creators are generated for each case reducer function
// export const { addCategory, deleteCategory } = counterSlice.actions

export default productSlice.reducer