import React, { useState } from 'react'
import './OrderDetail.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, EditBtn } from '../components';
import API from '../apis';
import swal from 'sweetalert'
import Swal from 'sweetalert2'
import moment from 'moment'
import { mapWord } from '../utils/utils';

const updatedMessage = (status) => {
  switch (status) {
    case 'pending':
      return 'Đơn hàng đang chờ được xác nhận'
    case 'preparing':
      return 'Đơn hàng đang được chuẩn bị'
    case 'ready-to-ship':
      return 'Đơn hàng đã sẵn sàng giao'
    case 'shipping':
      return 'Đơn hàng đang được vận chuyển'
    case 'success':
      return 'Đơn hàng đã giao thành công'
    case 'canceled':
      return 'Đơn hàng đã bị hủy'
  }
  return 'none'
}

export const OrderDetail = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const data = location.state;
  const [order, setOrder] = useState(data)
  const [status, setStatus] = useState(order?.status || '')
  const [updating, setUpdating] = useState(false)
  // const getDetailOrder = async () => {
  //   const response = await API.OrderAPI.getOrder(id)

  // }



  const updateStatus = async (status) => {
    let updated_history = [...order.updated_history, {
      date: new Date(),
      message: updatedMessage(status)
    }]
    const response = await API.OrderAPI.updateOrder({ ...order, status, updated_history })
    console.log('updated order', response)
    setOrder(response)
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Cập nhật thành công',
      showConfirmButton: true,
      confirmButtonText: 'OK'
    })
  }

  console.log('order ', order)


  return (
    <div>
      <div className='mb-8'><Link to='/orders'><span className='bg-white p-2 px-4 rounded-lg border mr-4 hover:bg-tertiary hover:text-white cursor-pointer'><FontAwesomeIcon icon={faChevronLeft} /></span></Link>
        <span className='font-bold text-xl'>ĐƠN HÀNG: {order._id}</span> </div>
      <div className='px-16'>
        <div className='flex gap-8'>
          <div className='container'>
            <div className='text-xl text-center mb-4'>Thông tin người nhận</div>
            <div>
              <div> <span className='font-semibold'>Người nhận: </span><span>{order.shipping_address.name}</span></div>
              <div> <span className='font-semibold'>Hình thức nhận hàng: </span><span>{mapWord(order.shipping)}</span></div>
              <div> <span className='font-semibold'>Phương thức thanh toán: </span><span>{mapWord(order.payment)}</span></div>
              <div> <span className='font-semibold'>Địa chỉ: </span><span>{order.shipping_address.address}</span> </div>
              <div> <span className='font-semibold'>Điện thoại: </span><span>{order.shipping_address.phone}</span> </div>
            </div>
          </div>

          <div className='container'>
            <div className='text-xl text-center mb-4'>Thông tin đơn hàng</div>
            <div>
              <div> <span className='font-semibold'>Trạng thái: </span>
                {
                  (status == 'success' || status == 'canceled') ? (<>{status}
                  </>) : (<>
                    <span className='mr-8'>
                      <select disabled={!updating} onChange={(e) => setStatus(e.target.value)} defaultValue={status}>
                        <option value='pending'>Chờ xác nhận</option>
                        <option value='preparing'>Đang chuẩn bị</option>
                        <option value='ready-to-ship' >Sẵn sàng giao</option>
                        <option value='shipping'>Đang vận chuyển</option>
                        <option value='succuess'>Thành công</option>

                      </select>
                    </span>
                    {updating ? (<Button style={'p-1 px-2 rounded-md text-white bg-tertiary text-semibold my-2'}
                      handleClick={() => {
                        updateStatus(status)
                        setUpdating(false)

                      }}>Cập nhật</Button>) : (<EditBtn handleEdit={() => setUpdating(true)}></EditBtn>)}

                  </>)
                }



              </div>
              <div> <span className='font-semibold'>Ngày đặt: </span><span>{moment(order.createdAt).format('DD/MM/YYYY - HH : mm')}</span></div>
              <div>
                <div className='font-semibold mt-2'>Lịch sử cập nhật: </div>
                {
                  order.updated_history?.reverse().map((item, idx) => (
                    <div className='px-4 mt-1'>
                      <div className='text-sm italic flex items-center gap-2'><span className={`w-3 h-3 rounded-full inline-block ${idx == 0 ? 'bg-orange-400' : 'bg-gray-300'}`} ></span><span> {moment(item.date).format('DD/MM/YYYY - HH : mm')} : {item.message}</span></div>
                    </div>
                  ))
                }
              </div>

            </div>
          </div>
        </div>
        <div className='container mt-8'>
          <div className='font-semibold mb-8 text-xl'>Sản phẩm</div>
          <div>
            {/* <div className='flex justify-between mb-2 mt-3'>
              <div className='flex gap-2'>
                <img className='product-img' src='https://lh3.googleusercontent.com/IIPxSuzVo2300G6bzjSmGlLXBzzDwrAqvfZ8pwC_Wq2ypEmUMrye6CRK1CzMLb8NDm2NmgcvOHMAJIXRVn439Xvl8vDQ_yRtWA=rw'></img>
                <div>
                  <div className='font-semibold'>Thiết bị quan sát/Camera</div>
                  <div>Sku: 200700098</div>
                  <div>Phân loại: attributes</div>
                </div>
              </div>
              <div>
                <div>569,000đ</div>
                <div>x2</div>
              </div>
            </div>
            <hr></hr>
            <div className='flex justify-between mb-2 mt-3'>
              <div className='flex gap-2'>
                <img className='product-img' src='https://lh3.googleusercontent.com/FDNema1WeL-JZ-uraQVkVbBeAT2_hiL08evzNDMsYSBPz3SEpsC7h_3-NPkGUD6qdhQPkHAa_ulEAENI7ot4yd-nhJs8ycc=rw'></img>
                <div>
                  <div className='font-semibold'>Thẻ nhớ Micro Sandisk Ultra 32GB SDSQUNR-032G-GN3MN</div>
                  <div>Sku: 200700098</div>
                  <div>Phân loại: attributes</div>
                </div>
              </div>
              <div>
                <div>569,000đ</div>
                <div>x2</div>
              </div>
            </div> */}
            {order.items.map((item, idx) => (
              <div className='flex justify-between mb-2 mt-3'>
                <div className='flex gap-2'>
                  <img className='product-img' src={item.img}></img>
                  <div>
                    <div className='font-semibold'>{item.title}</div>
                    <div>Sku: {item.sky}</div>
                    <div>Phân loại: attributes</div>
                  </div>
                </div>
                <div>
                  <div>{item.price}</div>
                  <div>x{item.quantity}</div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {order.note && (<div className='container mt-8'>
          <div className='font-semibold mb-8 text-xl'>Ghi chú</div>
          <div>{order.note}</div>
        </div>)}

        <div className='container mt-8'>
          <div className='text-right'>Tổng: 12,400,000đ</div>
          <div className='text-right'>Phí vận chuyển: 0đ</div>
          <div className='text-right'>Giảm giá: 300,000đ</div>
          <div className='text-right text-lg font-semibold'>Thành tiền: 12,300,000đ</div>
        </div>
      </div>
      <div>
        {!(order.status == 'canceled' || order.status == 'success') && (<Button style={'block w-[20%] px-4 py-2 rounded-md text-white bg-red-600 text-semibold my-10 m-auto'}
          handleClick={() => {
            //update state
            updateStatus('canceled')
            //swal
            swal({
              title: "Thông báo",
              text: "Hủy đơn thành công",
              icon: "success",
            });

            //navigate
            navigate('/orders')
          }}>Hủy đơn</Button>)}

      </div>
    </div>
    // <div>
    //   <div>Tinh trang</div>
    //   <div>OrderDetail</div>
    //   <div>Ngay dat</div>
    //   <div>Ma don</div>

    //   <div>User</div>

    //   <div>Phương thức thanh toán</div>

    //   <div>
    //     <div>Thông tin giao hàng</div>
    //     <div>Tên khách hàng</div>
    //     <div>SDT</div>
    //     <div>Dia chi</div>
    //   </div>

    //   <div>Cap nhat trang thai</div>
    //   <div>Huy don</div>

    // </div>
  )
}
