import React, { useEffect, useState } from 'react'
import { AddBtn, Button, DeleteInput, DeleteBtn, EditBtn, Modal, Input, RangeInput } from '../components'
import API from '../apis'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import { useDispatch } from 'react-redux'
import { addCategory, deleteCategory, updateCategory } from '../store/categorySlice'
import { closeModal, showModal } from '../store/modalSlice'
import Swal from 'sweetalert2'
import { faChevronDown, faChevronLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CategoryModal = ({ category }) => {
  const dispatch = useDispatch()
  const { name, tech_specs } = category
  const [indexTab, setIndexTab] = useState(2)
  const [filter, setFilter] = useState({
    label: 'Label filter',
    type: '',
    spec: '',
    category,
    select_type: '',
    excluded: []
  })

  const [preview, setPreview] = useState([])
  const [showPreview, setShowPreview] = useState(true)
  const [expandTechSpec, setExpandTechSpec] = useState(true)
  const [viewMorePreview, setViewMorePreview] = useState(false)


  const fetchPreviewFilter = async () => {
    const query = {
      category: name,
      spec: filter.spec
    }
    const filterValues = await API.ProductAPI.getFilterPreview(query)
    console.log('filter values: ', filterValues)
    setPreview(filterValues)
    return filterValues
  }

  const handleAddFilter = async () => {
    const { label, spec, type, select_type } = filter
    const category_filter = [...category.filters, { label, spec, type, select_type }]

    try {
      const update_category = await API.CategoryAPI.updateCategory({
        ...category, filters: category_filter
      })

      console.log('update category: ', update_category)
      dispatch(updateCategory(update_category))

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500
      })

    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        footer: '<a href="">Why do I have this issue?</a>'
      })

    }


  }
  useEffect(() => {
    fetchPreviewFilter()
    setShowPreview(true)
  }, [filter])


  useEffect(() => {
    console.log('category filter: ', category.filters)
  }, [])


  return (
    <div className='w-[90%] h-[90%] bg-white rounded-lg py-8 px-16 overflow-scroll'>
      <div>Cập nhật danh mục</div>
      <div className='flex'>
        <div className='cursor-pointer py-4 px-8' onClick={() => { setIndexTab(1) }}>Thông tin danh mục</div>
        <div className='cursor-pointer  py-4 px-8' onClick={() => { setIndexTab(2) }}>Filter</div>
      </div>

      <div>
        {
          (indexTab == 1) && (<div className='flex gap-4'>
            <div className='border shadow-md mb-5 p-6'>
              <div><span>Tên danh mục: </span>{name}</div>
              {tech_specs && (<div>
                <div className='flex gap-4'><div>Thông số kĩ thuật</div>
                  <div className='cursor-pointer'><FontAwesomeIcon icon={faChevronDown} /></div></div>
                <ul>
                  {tech_specs.map((item, idx) => <li key={idx}>{item}</li>)}
                </ul>

              </div>)}
            </div>
            <div className='border shadow-md p-6'>
              <div>Filter</div>
              <div>
                {category.filters.map((item, idx) => (
                  <div>{item.label}</div>
                ))}

              </div>
            </div>


          </div>



          )
        }
        {
          (indexTab == 2) && (
            <div className='w-full border shadow-md p-6 min-h-[400px] relative'>
              <div className='flex gap-16'>
                <div>
                  <div id='filter-form'>
                    <div>
                      <label>Nhãn</label>
                      <input type='text' onChange={(e) => setFilter(prev => ({ ...prev, label: e.target.value }))}></input>
                    </div>

                    <div>
                      <label>Thông số</label>
                      <select onChange={(e) => {
                        setFilter(prev => ({ ...prev, spec: e.target.value }))
                      }}>
                        {tech_specs.map((item, idx) => (
                          <option key={idx} value={item}>{item}</option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <label>Loại lựa chọn</label>
                      <select onChange={(e) => setFilter(prev => ({ ...prev, type: e.target.value }))}>
                        <option value='list'>Danh sách</option>
                        <option value='range'>Khoảng</option>
                      </select>
                    </div>
                    <div>
                      <label>Lựa chọn</label>
                      <select disabled={filter.type == 'range'} onChange={(e) => setFilter(prev => ({ ...prev, select_type: e.target.value }))}>
                        <option value='single'>Một</option>
                        <option value='multiple'>Nhiều</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className='w-[250px]'>
                  <div>Preview</div>
                  <div className='border py-4 px-6'>
                    <div className='font-semibold flex justify-between py-4 px-2 border'>
                      <span>{filter.label}</span>
                      <span className='cursor-pointer' onClick={() => setShowPreview(prev => !prev)}>{
                        showPreview ? (<FontAwesomeIcon icon={faChevronDown} />) : (<FontAwesomeIcon icon={faChevronLeft} />)
                      }</span>
                    </div>
                    {
                      showPreview && (
                        <div>

                          {
                            (filter.type == 'range') ? (<RangeInput />) : (
                              <>
                                {
                                  preview.map((item, idx) => {

                                    if (idx == 5 && !viewMorePreview) return (<div className='cursor-pointer text-center' onClick={() => setViewMorePreview(true)}>Xem thêm</div>)
                                    if (idx > 5 && !viewMorePreview) return

                                    return (
                                      <div className='flex gap-2'>
                                        {
                                          <>
                                            {
                                              filter.select_type == 'single' && (<input type='radio' name='filter-value'></input>)
                                            }
                                            {
                                              filter.select_type == 'multiple' && (<input type='checkbox' name='filter-value'></input>)
                                            }
                                            <div className='flex flex-1 justify-between items-center mb-2 ml-2'>
                                              <span>{item._id}</span>
                                              <span>({item.count})</span>
                                            </div>
                                          </>
                                        }
                                      </div>
                                    )
                                  })

                                }

                                {
                                  (preview.length > 4 && viewMorePreview) && (<div className='cursor-pointer text-center' onClick={() => setViewMorePreview(false)}>Thu gọn</div>)
                                }
                              </>
                            )
                          }



                        </div>
                      )
                    }

                  </div>
                </div>
              </div>
              <div className='absolute bottom-5 m-auto w-fit left-[50%]'>
                <Button handleClick={handleAddFilter}>Thêm filter</Button>
              </div>

            </div>
          )
        }
      </div>
      <Button handleClick={() => dispatch(closeModal())}>Đóng</Button>

    </div>
  )
}

const UpdateCategoryModal = ({ category }) => {
  const categories = useSelector(state => state.category.value)
  const dispatch = useDispatch()
  const [categoryName, setCategoryName] = useState(category.name)
  const [techSpecs, setTechSpecs] = useState(category.tech_specs)
  const [filters, setFilters] = useState(category.filters)

  const handleUpdateCategory = async () => {
    try {
      const update_category = await API.CategoryAPI.updateCategory(
        { ...category, name: categoryName, tech_specs: techSpecs, filters })

      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Cập nhật sản phẩm thành công',
        showConfirmButton: true,
      })

      const idx = categories.findIndex(item => item._id == update_category._id)

      const update_categories = [...categories]
      update_categories[idx] = update_category

      dispatch(updateCategory(update_categories))

      dispatch(closeModal())


    }
    catch {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Có lỗi xảy ra!',
      })

      dispatch(closeModal())

    }
  }


  return (
    <div className='border-2 py-8 px-16 rounded-lg shadow-lg w-[50%] h-[80%] m-auto bg-white overflow-y-scroll'>
      <div className='mb-10 text-center font-bold text-lg'>Cập nhật Danh mục</div>

      <div className='flex gap-4 mb-10 items-center'>
        <label htmlFor='category-name' className='font-semibold'>Tên danh mục</label>
        <Input value={categoryName} setValue={setCategoryName} width={400} />
      </div>

      <div className='flex'>
        <div className='w-[400px]'>
          <div className='font-semibold'>Thông số kĩ thuật</div>
          <div className='flex flex-col gap-4 mb-2 mt-2' id='tech-spec'>
            <div className='flex flex-col gap-4 mb-2 mt-2' id='tech-spec'>
              {
                techSpecs.map((item, idx) => (<div className='flex gap-2' key={idx}>
                  {/* <input type='text' className='border-2' onChange={(e) => techSpecs[idx] = e.target.value} /> */}

                  <Input width={250} value={techSpecs[idx]} setValue={(value) => {
                    let tech_specs = [...techSpecs]
                    tech_specs[idx] = value
                    setTechSpecs(tech_specs)
                  }}></Input>

                  <DeleteInput handleOnclick={() => {
                    console.log(idx)
                    console.log(techSpecs.filter((item, id) => id !== idx))
                    setTechSpecs(techSpecs.filter((item, id) => id !== idx))
                  }} />
                </div>))
              }

            </div>

          </div>
          <AddBtn handleOnClick={() => { setTechSpecs(prev => [...prev, '']) }} />

        </div>

        <div>
          <div className='font-semibold'>Filter</div>
          <div className='flex flex-col gap-4 mb-2 mt-2' id='tech-spec'>
            {
              filters.map((item, idx) => (<div className='flex gap-2' key={idx}>
                {/* <input type='text' className='border-2' onChange={(e) => filters[idx] = e.target.value} />
                <div></div> */}
                <Input width={250} value={filters[idx]} setValue={(value) => {
                  let filter_values = [...filters]
                  filter_values[idx] = value
                  setFilters(filter_values)
                }}></Input>
                <DeleteInput handleOnclick={() => {
                  setFilters(filters.filter((filter_item, id) => filter_item !== item))

                }} />
              </div>))
            }

          </div>
          <AddBtn handleOnClick={() => { setFilters(prev => [...prev, '']) }} />
        </div>
      </div>

      <div className='flex justify-center mt-10 gap-5'>
        <Button handleClick={handleUpdateCategory}><span>Cập nhật</span></Button>
        <Button handleClick={() => dispatch(closeModal())} style='px-4 py-2 rounded-md text-white bg-red-600 text-semibold my-2 hover:bg-red-700'><span>Hủy</span></Button>

      </div>
    </div>

  )

}


const AddCategory = ({ updateProductCallback }) => {
  const categories = useSelector(state => state.category.value)

  const [newCategory, setNewCategory] = useState('')
  const [techSpecs, setTechSpecs] = useState([])
  const [filters, setFilters] = useState([])
  const dispatch = useDispatch()

  const handleAddNewCategory = async () => {
    console.log('add new category')

    console.log(newCategory)
    console.log(techSpecs)
    console.log(filters)

    if (newCategory == '') return

    if (!!categories.find(c => c.name == newCategory)) {
      console.log('Category already existed!')
    }
    else {
      const category = await API.CategoryAPI.addCategory({ name: newCategory, tech_specs: techSpecs, filters })
      dispatch(addCategory(category))
      setTechSpecs([])
      setFilters([])
      setNewCategory('')

      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Đã thêm danh mục',
        showConfirmButton: false,
        timer: 1500
      })
    }
  }


  return (
    <div className='border-2 py-8 px-16 rounded-lg shadow-lg w-[50%] m-auto bg-white'>
      <div className='mb-10 text-center font-bold text-lg'>Thêm Danh mục</div>
      <div className='flex gap-4 mb-10 items-center'>
        <label htmlFor='category-name' className='font-semibold'>Tên danh mục</label>
        {/* <input type='text' id='category-name' className='border-2'
          value={newCategory} onChange={e => setNewCategory(e.target.value)}></input> */}
        <Input value={newCategory} setValue={setNewCategory} width={400} />
      </div>
      <div className='flex'>
        <div className='w-[400px]'>
          <div className='font-semibold'>Thông số kĩ thuật</div>
          <div className='flex flex-col gap-4 mb-2 mt-2' id='tech-spec'>
            {
              techSpecs.map((item, idx) => (<div className='flex gap-2' key={idx}>
                {/* <input type='text' className='border-2' onChange={(e) => techSpecs[idx] = e.target.value} /> */}

                <Input width={250} value={techSpecs[idx]} setValue={(value) => {
                  let tech_specs = [...techSpecs]
                  tech_specs[idx] = value
                  setTechSpecs(tech_specs)
                }}></Input>

                <DeleteInput handleOnclick={() => {
                  console.log(idx)
                  console.log(techSpecs.filter((item, id) => id !== idx))
                  setTechSpecs(techSpecs.filter((item, id) => id !== idx))
                }} />
              </div>))
            }

          </div>
          <AddBtn handleOnClick={() => { setTechSpecs(prev => [...prev, '']) }} />

        </div>
        <div>
          <div className='font-semibold'>Filter</div>

          <div className='flex flex-col gap-4 mb-2 mt-2' id='tech-spec'>
            {
              filters.map((item, idx) => (<div className='flex gap-2' key={idx}>
                {/* <input type='text' className='border-2' onChange={(e) => filters[idx] = e.target.value} />
                <div></div> */}
                <Input width={250} value={filters[idx]} setValue={(value) => {
                  let filter_values = [...filters]
                  filter_values[idx] = value
                  setFilters(filter_values)
                }}></Input>
                <DeleteInput handleOnclick={() => {
                  setFilters(filters.filter((filter_item, id) => filter_item !== item))

                }} />
              </div>))
            }

          </div>
          <AddBtn handleOnClick={() => { setFilters(prev => [...prev, '']) }} />

        </div>
      </div>

      <div className='flex justify-center mt-10 gap-5'>
        <Button handleClick={handleAddNewCategory}><span>Thêm danh mục</span></Button>
        <Button handleClick={() => dispatch(closeModal())} style='px-4 py-2 rounded-md text-white bg-red-600 text-semibold my-2 hover:bg-red-700'><span>Hủy</span></Button>

      </div>
    </div>
  )
}


export const Category = () => {

  const categories = useSelector(state => state.category.value)
  const dispatch = useDispatch()
  // const [categories, setCategories] = useState([])

  const handleDeleteCategory = async (id) => {
    await API.CategoryAPI.deleteCategory(id)
    const update_categories = categories.filter(item => item._id !== id)
    dispatch(updateCategory(update_categories))
  }

  const fetchCategory = async () => {
    const categories = await API.CategoryAPI.getCategories()
    console.log(categories)
    // setCategories(categories)
  }

  useEffect(() => {
    fetchCategory()
  }, [])


  return (
    <>
      <div className=''>
        <div className='text-3xl mb-10'>Danh mục sản phẩm</div>


        <div>




          <div className='border p-8 rounded-lg shadow-md flex-1'>
            <div className='mb-8 flex justify-between'>
              <input placeholder='Tìm kiếm' className='border-b-2 w-fit h-fit'></input>
              <div className='mr-10'>
                <Button style={'border-2 border-primary text-slate-600 rounded-lg w-fit cursor-pointer p-2 hover:bg-tertiary hover:text-white hover:border-tertiary'}
                  handleClick={() => {
                    dispatch(showModal(<AddCategory />))
                  }}>
                  <FontAwesomeIcon icon={faPlus} /><span className='ml-2'>Thêm danh mục</span>
                </Button>
              </div>

            </div>






            <div>
              <table>
                <thead>
                  <tr>
                    <th>Danh mục</th>
                    <th>Hành động</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map((category, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{category.name}</td>
                        <td className='flex justify-center gap-10 border-none'>
                          {/* <EditBtn handleEdit={() => {
                            dispatch(showModal(<CategoryModal category={category} />))
                          }} /> */}
                          <EditBtn handleEdit={() => {
                            dispatch(showModal(<UpdateCategoryModal category={category} />))
                          }} />
                          <DeleteBtn handleDelete={() => handleDeleteCategory(category._id)} />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>

          </div>
        </div >
      </div >


    </>
  )
}

