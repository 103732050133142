import axios from '../utils/axios'


const getProduct = (id) => axios({
  url: `/product/${id}`,
  method: 'get'
})

const getProducts = (params) => axios({
  url: '/product',
  method: 'get',
  params
})

const addProduct = (data) => axios({
  url: '/product',
  method: 'post',
  data
})



const updateProduct = (data) => axios({
  url: `/product/${data._id}`,
  method: 'put',
  data
})


const deleteProduct = (data) => axios({
  url: `/product/${data._id}`,
  method: 'delete',
  data
})


const getBrands = () => axios({
  url: '/product/brands',
  method: 'get'
})

const getFilter = (params) => axios({
  url: '/product/filter',
  method: 'get',
  params
})

const updateFilters = (data) => axios({
  url: '/product/filter',
  method: 'post',
  data
})


export default { getProducts, addProduct, getBrands, getFilter, updateFilters, getProduct, updateProduct, deleteProduct }
