import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { socketContext } from '../App'
import { faChartLine, faTags, faAnglesLeft, faAnglesRight, faHeadset, faPlus, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { signOut } from '../store/userSlice'
import { closeModal, showModal } from '../store/modalSlice'
import messageSound from '../assets/message.mp3'
import Button from './button/Button'
const NewOrderModal = ({ order }) => {

  const dispatch = useDispatch()
  useEffect(() => {
    const sound = new Audio(messageSound)
    sound.load()
    sound.play()
  }, [order])


  return (
    <div className='border-2 pt-2 pb-8 px-10 rounded-lg shadow-lg w-[40%] h-[30%] m-auto bg-white relative'>
      <div className='absolute right-4'><Button handleClick={() => {
        dispatch(closeModal())
      }}>X</Button></div>
      <div className='mb-6 text-center font-bold text-lg mt-6'>Thông báo</div>
      <div>
        <div className='mb-2'>Bạn có đơn hàng mới</div>
        <div>Đơn hàng <span onClick={() => { dispatch(`order/${order._id}`) }} className='text-primary'><Link to='/'>{order._id}</Link> </span>vừa đặt thành công và đang ở trạng thái cần xác nhận</div>
      </div>

      {/* <div className='mt-4 h-[70%] overflow-y-scroll border px-6 py-4'>
        <ul>
          <li className='mb-1'>Đơn hàng ABC123 đã đặt thành công và ở trạng thái cần xác nhận</li>
          <li className='mb-1'>Đơn hàng ABC123 đã đặt thành công và ở trạng thái đang chuẩn bị</li>
          <li className='mb-1'>Đơn hàng ABC123 đã đặt thành công và ở trạng thái cần xác nhận</li>
          <li className='mb-1'>Đơn hàng ABC123 đã đặt thành công và ở trạng thái cần xác nhận</li>
          <li className='mb-1'>Đơn hàng ABC123 đã đặt thành công và ở trạng thái cần xác nhận</li>
          <li className='mb-1'>Đơn hàng ABC123 đã đặt thành công và ở trạng thái cần xác nhận</li>
          <li className='mb-1'>Đơn hàng ABC123 đã đặt thành công và ở trạng thái cần xác nhận</li>
          <li className='mb-1'>Đơn hàng ABC123 đã đặt thành công và ở trạng thái cần xác nhận</li>

        </ul>
      </div> */}
    </div>
  )
}

export const Header = () => {

  const socket = useContext(socketContext)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [newNotifications, setNewNotifications] = useState(0)

  const handleLogout = () => {
    dispatch(signOut())
    navigate('/')
  }

  useEffect(() => {
    const notifyMenu = document.getElementById('notify-menu')
    const notifyBell = document.getElementById('notify-bell')
    if (notifyBell) {
      notifyBell.addEventListener('click', () => {
        console.log('Hello')
        notifyMenu.classList.toggle('hidden')
      })
    }
  }, [])

  useEffect(() => {
    console.log('socket: ', socket)
    if (socket) {
      //socket.emit('new-order')
      socket.on('new-order', (data) => {
        console.log('new order notification: ', data)
        dispatch(showModal(< NewOrderModal order={JSON.parse(data)} />))
        setNewNotifications(prev => prev + 1)
      })
    }
  }, [socket])


  return (
    <div className='w-full bg-white min-h-[60px] flex justify-end shadow-md items-center px-[80px]'>
      <div className='mb-2 cursor-pointer mr-3 hover:text-secondary hover:font-bold' onClick={handleLogout}>
        <FontAwesomeIcon icon={faPowerOff} /> Đăng xuất</div>

      <div id='notify-bell' className='relative cursor-pointer'>
        {newNotifications > 0 && <div className='absolute -top-[10px] -right-[8px] bg-red-500 rounded-[50%] w-[20px] h-[20px] flex justify-center items-center text-white'>{newNotifications}</div>}
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
        </svg>
      </div>

      <div id='notify-menu' className='hidden absolute top-[50px] bg-white shadow-lg border min-h-[300px] rounded-lg p-6'>
        <div className='py-3'>A new order is placed by user Truc NG</div>
        <hr></hr>
        <div className='py-3'>A new order is placed by user Truc NG</div>
        <hr></hr>
        <div className='py-3'>A new order is placed by user Truc NG</div>
        <hr></hr>
        <div className='py-3'>A new order is placed by user Truc NG</div>
      </div>


    </div>
  )
}
