import axios from '../utils/axios'


const getCategories = (params) => axios({
  url: '/category/all',
  method: 'get'

})

const addCategory = (data) => axios({
  url: '/category',
  method: 'post',
  data
})

const deleteCategory = (params) => axios({
  url: `/category/${params}`,
  method: 'delete',
})

const updateCategory = (data) => axios({
  url: `/category/${data._id}`,
  method: 'put',
  data
})

export default { getCategories, addCategory, deleteCategory, updateCategory }
