import React, { useEffect } from 'react'
import './Product.css'
import { DeleteBtn, EditBtn } from '../components'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import API from '../apis'
import { Pagination } from '@mui/material';
import { loaded, loading } from '../store/utilitySlice'



export const Product = () => {
  const categories = useSelector(state => state.category.value)
  const [allCategories, setAllCategory] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [products, setProducts] = useState([])
  const [page, setPage] = useState(1)
  const LIMIT = 10
  const dispatch = useDispatch()

  const fetchProducts = async () => {
    dispatch(loading())
    const query = {
      page,
      limit: LIMIT,
    }
    if (selectedCategory !== null) {
      query.category = selectedCategory
    }
    const products = await API.ProductAPI.getProducts(query)
    setProducts(products)
    dispatch(loaded())
    console.log('products: ', products)
    return products
  }

  const handleChangePage = (event, value) => {
    console.log('select value: ', value)
    setPage(value)
  }

  const handleChangeCategory = (event) => {
    if (event.target.value == 'Tất cả') {
      setSelectedCategory(null)
      return
    }
    setSelectedCategory(event.target.value)
  }

  useEffect(() => {
    fetchProducts()
  }, [page, selectedCategory])

  useEffect(() => {
    setAllCategory([{ name: 'Tất cả' }, ...categories])
  }, [])



  return (
    <div className=''>
      <div className='text-3xl mb-10'>Danh sách sản phẩm</div>
      <div className='border p-8 rounded-lg shadow-md'>
        <div className='flex justify-between mb-8'>
          <div className='flex gap-10'>
            <div className='flex gap-4'>
              <label htmlFor='category'>Danh mục</label>
              <select id='category' className='border' onChange={handleChangeCategory}>
                {
                  allCategories.map((item, idx) => <option key={idx} value={item.name}>{item.name} </option>)
                }
              </select>
            </div>

            <div className='flex gap-4'>
              <label htmlFor='category'>Trạng thái</label>
              <select id='category' className='border'>
                <option>Có sẵn</option>
                <option>Hết hàng</option>
              </select>
            </div>
          </div>
          <div><input placeholder='Tìm kiếm' className='border-b-2'></input></div>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                <th>Hình ảnh</th>
                <th>Tên sản phẩm</th>
                <th>Danh mục</th>
                <th>Thương hiệu</th>
                <th>Giá</th>
                <th>Tồn kho</th>
                <th>Hành động</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, idx) => {
                return (
                  <tr className='hover:bg-slate-200 cursor-pointer' key={idx}>
                    <td><img src={product.mainImg} style={{ maxWidth: '60px', maxHeight: '60px', margin: 'auto' }} /></td>
                    <td className='text-left px-8'>
                      <div>{product.title}</div>
                      <div className='text-xs text-primary font-semibold'>SKU: {product.variants[0].sku}</div>
                    </td>
                    <td>{product.category}</td>
                    <td>{product.brand}</td>
                    <td>{product.variants[0].price}</td>
                    <td>{product.variants[0].stock}</td>
                    <td className='flex justify-around border-none pt-5'>
                      <Link to={`/product/${product._id}`} state={product}>
                        <EditBtn />
                      </Link>
                    </td>
                  </tr>
                )
              }
              )}
            </tbody>
          </table>
          <div className='w-fit mt-8 alert m-auto'>
            <Pagination count={10} color="primary" onChange={handleChangePage} />
          </div>
        </div>

      </div>
    </div>
  )
}
