import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AddBtn, Button, DeleteInput, DeleteBtn, EditBtn, Modal, RangeInput, Input } from '../components'
import API from '../apis'
import { addCategory, deleteCategory, updateCategory } from '../store/categorySlice'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import Swal from 'sweetalert2'

export const AddCategory = () => {
  const categories = useSelector(state => state.category.value)

  const [newCategory, setNewCategory] = useState('')
  const [techSpecs, setTechSpecs] = useState([])
  const [filters, setFilters] = useState([])
  const dispatch = useDispatch()

  const handleAddNewCategory = async () => {
    console.log('add new category')

    console.log(newCategory)
    console.log(techSpecs)
    console.log(filters)

    if (newCategory == '') return

    if (!!categories.find(c => c.name == newCategory)) {
      console.log('Category already existed!')
    }
    else {
      const category = await API.CategoryAPI.addCategory({ name: newCategory, tech_specs: techSpecs, filters })
      dispatch(addCategory(category))
      setTechSpecs([])
      setFilters([])
      setNewCategory('')

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500
      })

    }
  }


  return (
    <div className='border-2 py-8 px-16 rounded-lg shadow-lg w-[70%] m-auto'>
      <div className='mb-10 text-center font-bold text-lg'>Thêm Danh mục</div>
      <div className='flex gap-4 mb-10 items-center'>
        <label htmlFor='category-name'>Tên danh mục</label>
        {/* <input type='text' id='category-name' className='border-2'
          value={newCategory} onChange={e => setNewCategory(e.target.value)}></input> */}
        <Input value={newCategory} setValue={setNewCategory} width={400} />
      </div>
      <div className='flex'>
        <div className='w-[400px]'>
          <div>Thông số kĩ thuật</div>
          <div className='flex flex-col gap-4 mb-2 mt-2' id='tech-spec'>
            {
              techSpecs.map((item, idx) => (<div className='flex gap-2' key={idx}>
                {/* <input type='text' className='border-2' onChange={(e) => techSpecs[idx] = e.target.value} /> */}

                <Input width={250} value={techSpecs[idx]} setValue={(value) => {
                  let tech_specs = [...techSpecs]
                  tech_specs[idx] = value
                  setTechSpecs(tech_specs)
                }}></Input>

                <DeleteInput handleOnclick={() => {
                  console.log(idx)
                  console.log(techSpecs.filter((item, id) => id !== idx))
                  setTechSpecs(techSpecs.filter((item, id) => id !== idx))
                }} />
              </div>))
            }

          </div>
          <AddBtn handleOnClick={() => { setTechSpecs(prev => [...prev, '']) }} />

        </div>
        <div>
          <div>Filter</div>

          <div className='flex flex-col gap-4 mb-2 mt-2' id='tech-spec'>
            {
              filters.map((item, idx) => (<div className='flex gap-2' key={idx}>
                {/* <input type='text' className='border-2' onChange={(e) => filters[idx] = e.target.value} />
                <div></div> */}
                <Input width={250} value={filters[idx]} setValue={(value) => {
                  let filter_values = [...filters]
                  filter_values[idx] = value
                  setFilters(filter_values)
                }}></Input>
                <DeleteInput handleOnclick={() => {
                  setFilters(filters.filter((filter_item, id) => filter_item !== item))

                }} />
              </div>))
            }

          </div>
          <AddBtn handleOnClick={() => { setFilters(prev => [...prev, '']) }} />

        </div>


      </div>



      <div className='flex justify-center mt-10'>
        <Button handleClick={handleAddNewCategory}><span>Thêm danh mục</span></Button>
      </div>
    </div>
  )
}

