import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API from '../apis'
const initialState = { value: null, is_signin: false, is_validated: false, isLoading: true }


//async thunk
export const getUser = createAsyncThunk('user/getUser',
  async (uid) => {
    try {

      // const response = await API.UserAPI.getUser()
      const response = await API.UserAPI.getUser({ uid })

      console.log('response get user: ', response)
      return response
    } catch (err) { console.log(err) }

  })


const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    signIn: (state, action) => {
      state.value = action.payload
      state.is_signin = true;
    },
    signOut: (state, action) => {
      state.is_signin = false;
      localStorage.removeItem('uid')
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')


    }
  },
  extraReducers: (builder) => {
    //get user reducer
    builder.addCase(getUser.pending, (state) => {
      console.log('Pending get user')
      state.isLoading = true;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      console.log('Fulfilled get user: ', action.payload)
      state.isLoading = false;
      if (action.payload?.role == 'admin') {
        state.is_signin = true;
        state.value = action.payload
      }
      // state.is_signin = action.payload?.role == 'member';
      // state.value = action.payload;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      console.log('Rejected get user')
      state.isLoading = false;
      state.is_signin = false;
      // state.errorMessage = action.payload.message;
    });
  }
})


export const { signIn, updateUser,signOut } = userSlice.actions

export default userSlice.reducer