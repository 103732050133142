import axios from '../utils/axios'


const getMessages = (params) => axios({
  url: '/chatsession',
  method: 'get',
  params
})

const getChats = (params) => axios({
  url: '/chats',
  method: 'get',
  params
})


export default { getMessages, getChats }