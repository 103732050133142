import React, { useState } from 'react'
import { Button } from '../components'
import { useDispatch } from 'react-redux'
import API from '../apis'
import { signIn } from '../store/userSlice'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'


const Signin = () => {
  const [email, setEmail] = useState('')
  const [pwd, setPwd] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSignin = async () => {
    const response = await API.UserAPI.signIn({ email, pwd })
    console.log('response: ', response)
    if (response.success && response.user.role == 'admin') {

      localStorage.setItem('access_token', response.accessToken)
      localStorage.setItem('refresh_token', response.refreshToken);
      localStorage.setItem('uid', response.user._id);



      dispatch(signIn(response.user))
      navigate('/')
    }
    else {
      console.log('Lỗi: ', response.message)
      Swal.fire({
        title: 'Không thành công',
        text: 'Thông tin đăng nhập không hợp lệ'
      })
    }
  }

  return (
    <div className='flex justify-center items-center bg-gradient-to-r from-primary to-lightblue h-screen'>
      <div className='border-2 w-1/2 px-8 py-16 text-lg h-[500px] w-[600px] shadow-lg bg-white rounded-lg'>
        <div id='head' className='font-semibold text-3xl text-center mb-4'>Đăng nhập</div>
        <div className='text-center'>
          BK-digital Administration
        </div>

        <div id='main' className='flex flex-col items-center justify-center w-full mt-12'>
          <div className='mb-6 flex text-left items-center'>
            <label className='w-[100px] font-semibold' htmlFor='email'>Email</label>
            <input type='email' name='email' className='border-2 py-1 px-4 rounded-lg w-[250px]' onChange={(e) => { setEmail(e.target.value) }} />
          </div>
          <div className='mb-20 flex text-left items-center'>
            <label className='w-[100px] font-medium' htmlFor='pwd' >Mật khẩu</label>
            <input type='password' name='pwd' className='border-2 py-1 px-4 rounded-lg w-[250px]' onChange={(e) => { setPwd(e.target.value) }} />
          </div>

          <Button handleClick={handleSignin} style={'border-2 border-darkblue rounded-lg px-4 py-3 w-[30%] hover:bg-primary hover:text-white'}>Đăng nhập</Button>

        </div>


      </div>
    </div>
  )
}

export default Signin