import { createSlice } from '@reduxjs/toolkit'


const initialState = {
  loading: false,
  isExpandSideBar: true
}

export const utilitySlice = createSlice({
  name: 'utility',
  initialState,
  reducers: {
    loaded: (state, action) => {
      state.loading = false
    },
    loading: (state, action) => {
      state.loading = true
    },
    expandSideBar: (state, action) => {
      state.isExpandSideBar = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { loaded, loading, expandSideBar } = utilitySlice.actions

export default utilitySlice.reducer