import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API from '../apis/index'

//async thunk
export const getCategories = createAsyncThunk('category/getcategories',
  async () => {
    try {
      const response = await API.CategoryAPI.getCategories()
      //check error code
      return response
    }
    catch (err) {
      console.log(err)
    }
  })


const initialState = {
  value: [],
}

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    addCategory: (state, action) => {
      state.value.push(action.payload)
    },


    updateCategory: (state, action) => {
      state.value = action.payload
    }

  },
  extraReducers: (builder) => {
    builder.addCase(getCategories.pending, () => {
      console.log('loading categories')
    })
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.value = action.payload
    })
    builder.addCase(getCategories.rejected, (state, action) => {
      console.log('categories rejected')
    })
  }
})

// Action creators are generated for each case reducer function
export const { addCategory, updateCategory } = categorySlice.actions

export default categorySlice.reducer