import React, { useEffect, useState } from 'react';
import { Jodit } from 'jodit'
import JoditReact from "jodit-react";
import { FileUpload } from '../utils/cloudinaryServices';
// import { FileUpload } from '../../services/cloudinaryService';

class Editor extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      editorContent: '',
      editorNodes: []
    }

    this.editorConfig = {
      readonly: false,
      autofocus: true,
      tabIndex: 1,

      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: 'insert_clear_html',

      placeholder: 'Write something awesome ...',
      beautyHTML: true,
      toolbarButtonSize: "large",
      buttons: [
        'source',
        '|', 'bold', 'italic',
        '|', 'ul', 'ol',
        '|', 'font', 'fontsize',
        '|', 'link',
        '|', 'left', 'center', 'right', 'justify',
        '|', 'undo', 'redo',
        '|', 'hr', 'fullsize'
      ],
      extraButtons: ["uploadImage", "heading"]
    }
  }

  componentWillMount() {
    this.uploadImageButton();
    this.headingButton();

  }

  uploadImageButton = () => {
    Jodit.defaultOptions.controls.uploadImage = {
      name: 'Upload image to Cloudinary',
      iconURL: "https://www.kindpng.com/picc/m/261-2619141_cage-clipart-victorian-cloud-upload-icon-svg-hd.png",
      exec: (async (editor) => {
        await this.imageUpload(editor);
      })
    };
  }

  headingButton = () => {

    const headingStyle = {
      h1: 'font-weight: bold; font-size: 32px',
      h2: 'font-weight: bold; font-size: 26px',
      h3: 'font-weight: 700; font-size: 24px',
      h4: 'font-size: 20px'

    }

    Jodit.defaultOptions.controls.heading = {
      name: 'Heading',
      list: {
        h1: "Heading 1",
        h2: "Heading 2",
        h3: "Heading 3",
        h4: "Heading 4",
      },
      // iconURL: "https://cdn.icon-icons.com/icons2/2406/PNG/512/codeblock_editor_highlight_icon_145997.png",
      exec: (async (editor, t, { control }) => {
        console.log('args: ', control.args)
        const type = control.args[0]
        const heading = editor.selection.j.createInside.element(type);
        heading.style = headingStyle[type] // this can be done by adding an editor class: editorCssClass: my-class - see doc https://xdsoft.net/jodit/v.2/doc/Jodit.defaultOptions.html
        heading.innerHTML = `${editor.selection.html}`;
        editor.selection.insertNode(heading);
      }),
    };
  }

  imageUpload = (editor) => {

    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async function () {

      const imageFile = input.files[0];

      if (!imageFile) {
        return;
      }

      if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
        return;
      }

      const imageInfo = await FileUpload(imageFile)

      this.insertImage(editor, imageInfo.url);

    }.bind(this);
  }

  insertImage = (editor, url) => {
    const image = editor.selection.j.createInside.element('img');
    image.setAttribute('src', url);
    editor.selection.insertNode(image);
  }

  parseContent

  onChange = (value) => {
    console.log('value change: ', value)
  }


  setContent = (newContent) => {
    console.log('content: ', newContent)

    this.props.setContent(newContent)

  }

  render() {
    return (
      <React.Fragment>
        <JoditReact
          value={this.state.editorContent}
          config={this.editorConfig}
          onChange={this.onChange.bind(this)}
          onBlur={newContent => this.setContent(newContent)}
        />
      </React.Fragment>
    )
  }
}

// const editorConfig = {
//   readonly: false,
//   autofocus: true,
//   tabIndex: 1,

//   askBeforePasteHTML: false,
//   askBeforePasteFromWord: false,
//   defaultActionOnPaste: 'insert_clear_html',

//   placeholder: 'Write something awesome ...',
//   beautyHTML: true,
//   toolbarButtonSize: "large",
//   buttons: [
//     'source',
//     '|', 'bold', 'italic',
//     '|', 'ul', 'ol',
//     '|', 'font', 'fontsize',
//     '|', 'link',
//     '|', 'left', 'center', 'right', 'justify',
//     '|', 'undo', 'redo',
//     '|', 'hr', 'fullsize'
//   ],
//   extraButtons: ["uploadImage", "heading"]
// }


// const Editor = () => {

//   const [editorContent, setEditorContent] = useState('')

//   const uploadImageButton = () => {
//     Jodit.defaultOptions.controls.uploadImage = {
//       name: 'Upload image to Cloudinary',
//       iconURL: "https://www.kindpng.com/picc/m/261-2619141_cage-clipart-victorian-cloud-upload-icon-svg-hd.png",
//       exec: (async (editor) => {
//         await imageUpload(editor);
//       })
//     };
//   }

//   const headingButton = () => {

//     const headingStyle = {
//       h1: 'font-weight: bold; font-size: 32px',
//       h2: 'font-weight: bold; font-size: 26px',
//       h3: 'font-weight: 700; font-size: 24px',
//       h4: 'font-size: 20px'

//     }

//     Jodit.defaultOptions.controls.heading = {
//       name: 'Heading',
//       list: {
//         h1: "Heading 1",
//         h2: "Heading 2",
//         h3: "Heading 3",
//         h4: "Heading 4"
//       },
//       // iconURL: "https://cdn.icon-icons.com/icons2/2406/PNG/512/codeblock_editor_highlight_icon_145997.png",
//       exec: (async (editor, t, { control }) => {
//         console.log('args: ', control.args)
//         const type = control.args[0]
//         const heading = editor.selection.j.createInside.element(type);
//         heading.style = headingStyle[type] // this can be done by adding an editor class: editorCssClass: my-class - see doc https://xdsoft.net/jodit/v.2/doc/Jodit.defaultOptions.html
//         heading.innerHTML = `${editor.selection.html}`;
//         editor.selection.insertNode(heading);
//       }),
//     };
//   }

//   const insertImage = (editor, url) => {
//     const image = editor.selection.j.createInside.element('img');
//     image.setAttribute('src', url);
//     editor.selection.insertNode(image);
//   }

//   const imageUpload = (editor) => {

//     const input = document.createElement('input');
//     input.setAttribute('type', 'file');
//     input.setAttribute('accept', 'image/*');
//     input.click();

//     input.onchange = async function () {

//       const imageFile = input.files[0];

//       if (!imageFile) {
//         return;
//       }

//       if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
//         return;
//       }

//       const imageInfo = await FileUpload(imageFile)

//       insertImage(editor, imageInfo.url);

//     }
//   }




//   useEffect(() => {
//     uploadImageButton()
//     headingButton()

//   }, [])




//   // onChange = (value) => { }

//   // setContent = (newContent) => {
//   //   console.log('content: ', newContent)
//   //   this.setState({
//   //     editorContent: newContent
//   //   });
//   // }


//   return (
//     <React.Fragment>
//       <JoditReact
//         value={editorContent}
//         config={editorConfig}
//         onBlur={newContent => setEditorContent(newContent)}
//       />
//     </React.Fragment>
//   )

// }


export default Editor;
