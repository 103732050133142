import React, { useEffect, useState } from 'react'
import { AddBtn, Button, DeleteInput, DeleteBtn, EditBtn, Input } from '../components'
import API from '../apis'
import './Discount.css'
import { useDispatch } from 'react-redux'
import { closeModal, showModal } from '../store/modalSlice'
import Swal from 'sweetalert2'
import moment from 'moment'
import { faChevronDown, faChevronLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const AddDiscount = () => {
  const dispatch = useDispatch()
  const [quantity, setQuantity] = useState('')
  const [code, setCode] = useState('')
  const [amount, setAmount] = useState('')
  const [minOrderValue, setMinOrderValue] = useState('')
  const [validDate, setValidDate] = useState('')
  const [expireDate, setExpireDate] = useState('')

  const handleAddDiscount = async () => {

    if (code == '' || quantity == '' || amount == '' || validDate == '' || expireDate == '') {
      Swal.fire({
        title: 'Thông tin không hợp lệ',
        text: "Thông tin không hợp lệ, vui lòng kiểm tra lại!",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'OK'

      })
      return

    }



    const data = {
      code,
      quantity,
      amount,
      start_date: validDate,
      end_date: expireDate
    }


    if (minOrderValue != '') {
      data.min_order_value = minOrderValue
    }


    const discount = await API.DiscountAPI.addDiscount(data)
    console.log('add discount: ', discount)

    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Đã thêm mã',
      showConfirmButton: false,
      timer: 1500
    })


  }


  return (<>
    <div className='border py-8 px-12 rounded-lg shadow-md bg-white w-[40%]'>
      <div className='mb-10 text-center font-semibold text-xl'>Thêm Khuyến Mãi</div>
      <div className='flex flex-col gap-6 mb-10'>
        <div className='flex justify-between'>
          <div className='flex gap-4'>
            <label htmlFor='category-name' className='w-[60px]'>Mã</label>
            <Input type='text' width={200} value={code} setValue={setCode} />
          </div>
          <div className='flex gap-4'>
            <label htmlFor='category-name' >Số lượng</label>
            {/* <input type='number' id='quantity' className={`input-text w-[80px] ${}`} min='1' ></input> */}
            <Input type='number' width={80} value={quantity} setValue={setQuantity} />
          </div>
        </div>
        <div className='flex gap-4'>
          <label htmlFor='category-name' className='w-[60px]'>Giá trị</label>
          <Input type='number' width={200} value={amount} setValue={setAmount} />
        </div>
        <div className='flex gap-4 mt-6'>
          <label htmlFor='category-name'>Giới hạn giá trị đơn</label>
          <Input type='number' width={200} value={minOrderValue} setValue={setMinOrderValue} />
        </div>
        <div className='flex justify-center gap-10'>
          <div>
            <div>Ngày hiệu lực: </div>
            <Input type='date' width={200} value={validDate} setValue={setValidDate} />
          </div>
          <div>
            <div>Ngày hết hạn: </div>
            <Input type='date' width={200} value={expireDate} setValue={setExpireDate} />
          </div>
        </div>
      </div>
      <div className='flex justify-center gap-5'>
        <Button handleClick={handleAddDiscount}><span>Thêm mã</span></Button>
        <Button handleClick={() => dispatch(closeModal())} style='px-4 py-2 rounded-md text-white bg-red-600 text-semibold my-2 hover:bg-red-700'><span>Hủy</span></Button>
      </div>
    </div>
  </>)
}

const UpdateDiscountModal = ({ discount }) => {

  const dispatch = useDispatch()

  // const handleUpdateDiscount = async () => {
  //   const updated_discount = await API.DiscountAPI.updateDiscount({ ...discount, quantity })
  // }
  const [quantity, setQuantity] = useState(discount.quantity)


  const handleUpdateDiscount = async () => {
    try {
      const updated_discount = await API.DiscountAPI.updateDiscount({ ...discount, quantity })

      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Cập nhật mã thành công',
        showConfirmButton: true,
      })


      dispatch(closeModal())


    }
    catch {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Có lỗi xảy ra!',
      })

      dispatch(closeModal())

    }
  }



  return (
    <div className='border-2 py-8 px-16 rounded-lg shadow-lg w-[40%] h-[65%] m-auto bg-white overflow-y-scroll'>
      <div className='mb-10 text-center font-bold text-lg'>Cập nhật Mã khuyến mãi</div>

      <div className='flex flex-col gap-6 mb-10 mt-8'>
        <div className='flex gap-[100px]'>
          <div className='flex gap-4 w-[200px]'>
            <label htmlFor='category-name' className='w-[60px] font-semibold'>Mã:</label>
            <div>{discount.code}</div>
          </div>
          <div className='flex gap-4'>
            <label htmlFor='category-name' className='font-semibold' >Số lượng</label>
            <Input type='number' width={80} value={quantity} setValue={setQuantity} />
          </div>
        </div>
        <div className='flex gap-[100px]'>
          <div className='flex gap-4 w-[200px]'>
            <label htmlFor='category-name' className='w-[60px] font-semibold'>Giá trị:</label>
            <div>{discount.amount}</div>
          </div>
          <div className='flex gap-4'>
            <label htmlFor='category-name' className='font-semibold' >Đã sử dụng:</label>
            <div>{discount.used}</div>
          </div>
        </div>


        <div className='flex gap-4 mt-4'>
          <label htmlFor='category-name' className='font-semibold'>Giới hạn giá trị đơn:</label>
          <div>{discount.min_order_value}</div>
        </div>
        <div className='flex gap-10'>
          <div>
            <div><span className='font-semibold'>Ngày hiệu lực:</span> {moment(discount.start_date).format('DD-MM-YYYY')}</div>
          </div>
          <div>
            <div><span className='font-semibold'>Ngày hết hạn:</span> {moment(discount.end_date).format('DD-MM-YYYY')} </div>
          </div>
        </div>
      </div>

      <div className='flex justify-center mt-10 gap-5'>
        <Button handleClick={handleUpdateDiscount}><span>Cập nhật</span></Button>
        <Button handleClick={() => dispatch(closeModal())} style='px-4 py-2 rounded-md text-white bg-red-600 text-semibold my-2 hover:bg-red-700'><span>Hủy</span></Button>

      </div>


    </div>

  )

}

export const Discount = () => {
  const [discounts, setDiscounts] = useState([])
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const LIMIT = 10
  const fetchDiscounts = async () => {
    const discounts = await API.DiscountAPI.getDiscounts({ page, limit: LIMIT })
    setDiscounts(discounts)
  }

  useEffect(() => { fetchDiscounts() }, [])

  return (
    <div className=''>

      <div className='text-3xl mb-10'>Khuyến mãi</div>

      <div className='border p-8 rounded-lg shadow-md w-full'>

        <div className='mb-8 flex justify-between'>
          <input placeholder='Tìm kiếm' className='border-b-2 w-fit h-fit'></input>
          <div className='mr-10'>
            <Button style={'border-2 border-primary text-slate-600 rounded-lg w-fit cursor-pointer p-2 hover:bg-tertiary hover:text-white hover:border-tertiary'}
              handleClick={() => {
                dispatch(showModal(<AddDiscount />))
              }}>
              <FontAwesomeIcon icon={faPlus} /><span className='ml-2'>Thêm mã</span>
            </Button>
          </div>

        </div>

        <div>
          <table>
            <thead>
              <tr>
                <th>Mã</th>
                <th>Giá trị</th>
                <th>Ngày có hạn</th>
                <th>Ngày hết hạn</th>
                <th>Tổng</th>
                <th>Đã sử dụng</th>
                <th>Hành động</th>
              </tr>
            </thead>
            <tbody>
              {discounts.map((discount, idx) => {
                return (
                  <tr key={idx}>
                    <td className='text-tertiary font-semibold'>{discount.code}</td>
                    <td>{discount.amount}</td>
                    <td>{moment(discount.start_date).format('DD-MM-YYYY')}</td>
                    <td>{moment(discount.end_date).format('DD-MM-YYYY')}</td>
                    <td>{discount.quantity}</td>
                    <td>{discount.used}</td>
                    <td className='flex justify-center gap-6 border-none'>
                      <EditBtn handleEdit={() => {
                        dispatch(showModal(<UpdateDiscountModal discount={discount} />))
                      }} />
                      <DeleteBtn handleDelete={() => { }} />

                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

      </div>


    </div>
  )
}
