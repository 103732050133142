import React, { useEffect, useState } from 'react'
import { DeleteBtn, EditBtn, Button } from '../components'
import { closeModal, showModal } from '../store/modalSlice'
import { useDispatch } from 'react-redux'
import API from '../apis/index'
import Swal from 'sweetalert2'
import { mapWord } from '../utils/utils'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { Pagination } from '@mui/material';


const UserModal = ({ user }) => {

  const navigate = useNavigate()
  const updateBlackList = async (value) => {
    const data = {
      uid: user._id,
      updated_info: { ...user, is_blacklist: value }
    }
    console.log('user update data: ', data)
    const res = await API.UserAPI.updateUser(data)
    console.log('update blacklist: ', res)
    Swal.fire({
      title: 'Cập nhật thành công',
      icon: 'success',
      timer: 2000

    })

    dispatch(closeModal())
  }


  const dispatch = useDispatch()
  return (<div className='min-w-[600px] h-[400px] bg-white rounded py-8 px-10 rounded-lg'>
    <div className='font-semibold text-xl text-center mb-6'>Thông tin tài khoản</div>
    <div className='flex gap-8 items-start'>
      <img width='100px' height='100px' src={user.avatar || 'https://khoinguonsangtao.vn/wp-content/uploads/2022/07/avatar-cute-2.jpg'}></img>
      <form>
        <div className='mb-1'>
          <span className='font-semibold'>Họ tên: </span>
          <span>{user.name}</span>
        </div>
        <div className='mb-1'>
          <span className='font-semibold'>Email: </span>
          <span>{user.email}</span>
        </div>
        <div className='mb-1'>
          <span className='font-semibold'>Số điện thoại: </span>
          <span>{user.phone}</span>
        </div>
        <div className='mb-1'>
          <span className='font-semibold'>Ngày sinh: </span>
          <span>{user.date}</span>
        </div>
        <div className='mb-1'>
          <span className='font-semibold'>Giới tính: </span>
          <span>{mapWord(user.gender)}</span>
        </div>
        <div className='mb-1'>
          <span className='font-semibold'>Xác thực tài khoản: </span>
          <span>{user.is_validated ? 'Đã kích hoạt' : 'Chưa kích hoạt'}</span>
        </div>
        <div className='mb-1'>
          <span className='font-semibold'>Trạng thái: </span>
          {user.is_blacklist ? <span className='text-red-600 font-semibold'>Bị chặn</span> : <span >Đang hoạt động</span>}

        </div>
        <div className='flex justify-center mt-4 gap-6 items-center'>

          {user.is_blacklist ? (<Button style={'text-tertiary hover:text-primary font-medium'} handleClick={() => { updateBlackList(false) }}>Gỡ blacklist</Button>
          ) : (<Button style={'text-tertiary hover:text-primary font-medium'} handleClick={() => { updateBlackList(true) }}>Đánh dấu blacklist</Button>
          )}

          <Button handleClick={() => dispatch(closeModal())}>Đóng</Button>
        </div>
      </form>
    </div>
  </div>)
}

export const User = () => {
  const [users, setUsers] = useState([])
  const [status, setStatus] = useState('')

  const fetchUsers = async () => {
    const users = await API.UserAPI.getUsers({ page: 1, limit: 10, status })
    console.log('users: ', users)

    setUsers(users)
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  useEffect(() => {
    fetchUsers()
    console.log('status: ', status)
  }, [status])



  const dispatch = useDispatch()
  return (
    <div className=''>
      <div className='text-3xl mb-10'>Người dùng</div>
      <div className='border p-8 rounded-lg shadow-md w-[1200px]'>
        <div className='flex justify-between mb-8'>
          <div className='flex gap-10'>
            <div className='flex gap-4 items-center justify-center'>
              <label htmlFor='category'>Trạng thái</label>
              <select id='category' className='border-2 p-1 rounded-lg shadow-md' onChange={(e) => setStatus(e.target.value)}>
                <option value="active">Đang hoạt động</option>
                <option value="inactive">Bị chặn</option>
                <option value="">Tất cả</option>

              </select>
            </div>
          </div>
          <div><input placeholder='Tìm kiếm' className='border-b-2'></input></div>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                <th>Avatar</th>
                <th>Tên</th>
                <th>Email</th>
                <th>Điện thoại</th>
                <th>Trạng thái</th>
                <th>Ngày tham gia</th>
                <th>Hành động</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, idx) => {
                return (
                  <tr className='hover:bg-slate-200 cursor-pointer' key={idx}>
                    <td className='py-2'><img src={user.avatar || 'https://khoinguonsangtao.vn/wp-content/uploads/2022/07/avatar-cute-2.jpg'} style={{ maxWidth: '40px', height: '40px', margin: 'auto' }} /></td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.phone}</td>
                    {user.is_blacklist ? <td className='text-red-600 font-semibold'>Bị chặn</td> : <td >Đang hoạt động</td>}
                    <td>{moment(user.createdAt).format('DD-MM-YYYY')}</td>
                    <td className='flex justify-around border-none'>
                      <EditBtn handleEdit={() => {
                        dispatch(showModal(<UserModal user={user} />))
                      }} />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>

          <div className='w-fit mt-8 alert m-auto'>
            <Pagination count={10} color="primary" onChange={() => { }} />
          </div>

        </div>

      </div>
    </div >
  )
}

