import { createSlice } from '@reduxjs/toolkit'


const initialState = {
  showModal: false,
  children: null,
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal: (state, action) => {
      state.showModal = true
      state.children = action.payload
    },
    closeModal: (state, action) => {
      state.showModal = false
      state.children = null

    }

  },
})

// Action creators are generated for each case reducer function
export const { showModal, closeModal } = modalSlice.actions

export default modalSlice.reducer