import axios from '../utils/axios'


const getDiscounts = (params) => axios({
  url: '/discount',
  method: 'get',
  params
})

const addDiscount = (data) => axios({
  url: '/discount',
  method: 'post',
  data
})

const getDiscount = (code) => axios({
  url: `/discount/${code}`,
  method: 'get',
})

const updateDiscount = (data) => axios({
  url: `/discount/${data.code}`,
  method: 'put',
  data
})




export default { getDiscounts, addDiscount, getDiscount, updateDiscount }
