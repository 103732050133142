import React from 'react'

export const AddBtn = ({ handleOnClick }) => {
  return (
    <button className='flex gap-1 hover:text-primary cursor-pointer text-slate-800' type='button'
      onClick={() => { handleOnClick && handleOnClick() }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <span>Thêm</span>
    </button>
  )
}
