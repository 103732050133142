import React, { memo } from 'react'

const Modal = ({ children }) => {
  return (
    <div className='fixed inset-0 z-50 bg-black/50 flex items-center justify-center'>
      {children}
    </div>
  )
}

export default memo(Modal)