import React, { useState, useEffect } from 'react'
import { Button, EditBtn } from '../components'
import { useDispatch } from 'react-redux'
import { closeModal, showModal } from '../store/modalSlice'
import { Link } from 'react-router-dom'
import API from '../apis'
import { mapWord } from '../utils/utils'
import moment from 'moment'
import { Pagination } from '@mui/material';


const OrderModal = () => {
  const dispatch = useDispatch()
  return (<div className='min-w-[400px] h-[400px] bg-white rounded-lg'>
    Order modal
    <Button handleClick={() => dispatch(closeModal())}><span>Đóng</span></Button>

  </div>)
}

export const Order = () => {
  const dispatch = useDispatch()
  const [status, setStatus] = useState('pending')
  const [orders, setOrders] = useState([])
  const [page, setPage] = useState(1)
  const [orderStatus, setOrderStatus] = useState([])
  const LIMIT = 10

  const fetchOrders = async () => {
    const response = await API.OrderAPI.getOrders({ status, page, limit: LIMIT })
    setOrders(response)
  }

  const fetchOrderStatus = async () => {
    const response = await API.OrderAPI.getOrderStatus()
    let status = {}
    response.forEach(item => status[item._id] = item.total)
    setOrderStatus(status)
  }

  useEffect(() => {
    fetchOrders()

  }, [status, page])

  useEffect(() => {
    fetchOrderStatus()
  }, [])

  return (
    <div className=''>
      <div className='text-3xl mb-10'>Danh sách đơn hàng</div>
      <div className='mb-10 flex gap-10 flex-wrap'>
        <div onClick={() => { setStatus('pending') }} className={`h-[90px] w-[150px] ${status == 'pending' ? 'bg-tertiary text-white' : 'bg-white text-gray-600 border border-primary'} rounded-lg font-bold  p-2 text-center cursor-pointer`}>
          <div className={`mb-1 ${status == 'pending' ? '' : 'text-primary'}`}>Cần xác nhận</div>
          <div className='text-3xl'>{orderStatus['pending'] || 0}</div>
        </div>
        <div onClick={() => { setStatus('preparing') }} className={`h-[90px] w-[150px] ${status == 'preparing' ? 'bg-tertiary text-white' : 'bg-white text-gray-600 border border-primary'} rounded-lg font-bold  p-2 text-center cursor-pointer`}>
          <div className={`mb-1 ${status == 'preparing' ? '' : 'text-primary'}`}>Đang chuẩn bị</div>
          <div className='text-3xl'>{orderStatus['preparing'] || 0}</div>
        </div>
        <div onClick={() => { setStatus('ready-to-ship') }} className={`h-[90px] w-[150px] ${status == 'ready-to-ship' ? 'bg-tertiary text-white' : 'bg-white text-gray-600 border border-primary'} rounded-lg font-bold  p-2 text-center cursor-pointer`}>
          <div className={`mb-1 ${status == 'ready-to-ship' ? '' : 'text-primary'}`}>Sẵn sàng giao</div>
          <div className='text-3xl'>{orderStatus['ready-to-ship'] || 0}</div>
        </div>
        <div onClick={() => { setStatus('shipping') }} className={`h-[90px] w-[150px] ${status == 'shipping' ? 'bg-tertiary text-white' : 'bg-white text-gray-600 border border-primary'} rounded-lg font-bold  p-2 text-center cursor-pointer`}>
          <div className={`mb-1 ${status == 'shipping' ? '' : 'text-primary'}`}>Đang giao</div>
          <div className='text-3xl'>{orderStatus['shipping'] || 0}</div>
        </div>
        <div onClick={() => { setStatus('success') }} className={`h-[90px] w-[150px] ${status == 'success' ? 'bg-tertiary text-white' : 'bg-white text-gray-600 border border-primary'} rounded-lg font-bold  p-2 text-center cursor-pointer`}>
          <div className={`mb-1 ${status == 'success' ? '' : 'text-primary'}`}>Thành công</div>
          <div className='text-3xl'>{orderStatus['success'] || 0}</div>
        </div>
        <div onClick={() => { setStatus('canceled') }} className={`h-[90px] w-[150px] ${status == 'canceled' ? 'bg-tertiary text-white' : 'bg-white text-gray-600 border border-primary'} rounded-lg font-bold  p-2 text-center cursor-pointer`}>
          <div className={`mb-1 ${status == 'canceled' ? '' : 'text-primary'}`}>Đã hủy</div>
          <div className='text-3xl'>{orderStatus['canceled'] || 0}</div>
        </div>
      </div>
      <div className='border-2 p-8 rounded-lg shadow-lg'>
        <div className='flex justify-between mb-8'>
          <div className='flex gap-10'>
            <div className='flex gap-4'>
              <label htmlFor='category'>Trạng thái</label>
              <select id='category' className='border-2 px-4 py-1 rounded-lg shadow-sm' onChange={(e) => setStatus(e.target.value)}>
                <option value='pending'>Cần xác nhận</option>
                <option value='preparing'>Đang chuẩn bị</option>
                <option value='ready-to-ship'>Sẵn sàng giao</option>
                <option value='shipping'>Đang giao</option>
                <option value='success'>Thành công</option>
                <option value='canceled' >Đã hủy</option>
              </select>
            </div>
          </div>
          <div><input placeholder='Tìm kiếm' className='border-none border-b-2 border-red-600'></input></div>
        </div>
        <div>
          {orders.length == 0 ? (<div> Không có đơn hàng nào</div>) : (<div><table>
            <thead>
              <tr>
                <th>Mã đơn</th>
                <th>Ngày đặt</th>
                <th>Thanh toán</th>
                <th>Giao hàng</th>
                <th>Trạng thái</th>
                <th>Hành động</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, idx) => {
                return (
                  <tr className='hover:bg-slate-200 cursor-pointer' key={idx}>
                    <td>{order._id}</td>
                    <td>{moment(order.createdAt).format('DD-MM-YYYY')}</td>
                    <td>{mapWord(order.payment)}</td>
                    <td>{mapWord(order.shipping)}</td>
                    <td className='text-primary font-medium'>{mapWord(order.status)}</td>
                    <td className='flex justify-around border-none'>
                      {/* <EditBtn handleEdit={() => {
                        console.log('scrolled: ', window.scrollY)
                        dispatch(showModal(<OrderModal />))
                      }} /> */}
                      <Link to={`/order/${order._id}`} state={order}><EditBtn></EditBtn></Link>
                      {/* <Button style={'bg-red-600 py-1 px-2 rounded-lg text-white hover:bg-red-500'}><span>Hủy đơn</span></Button> */}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
            <div className='w-fit mt-8 alert m-auto'>
              <Pagination count={10} color="primary" onChange={() => { }} />
            </div>
          </div>

          )}

        </div>

      </div>
    </div>
  )
}
