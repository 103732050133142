import { configureStore } from '@reduxjs/toolkit'
import categorySlice from './categorySlice'
import modalSlice from './modalSlice'
import utilitySlice from './utilitySlice'
import productSlice from './productSlice'
import userSlice from './userSlice'

export const store = configureStore({
  reducer: {
    category: categorySlice,
    modal: modalSlice,
    utility: utilitySlice,
    product: productSlice,
    user: userSlice,
  },
})