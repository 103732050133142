import axios from '../utils/axios'

const getTotalRevenue = (params) => axios({
  url: '/dashboard/revenue',
  method: 'get',
  params
})

const getTotalOrder = (params) => axios({
  url: '/dashboard/order',
  method: 'get',
  params
})

const getTotalProduct = (params) => axios({
  url: '/dashboard/product',
  method: 'get',
  params
})

const getTotalUser = (params) => axios({
  url: '/dashboard/user',
  method: 'get',
  params
})

const getRevenues = (params) => axios({
  url: '/dashboard/revenues',
  method: 'get',
  params
})

const getOrderCount = (params) => axios({
  url: '/dashboard/order-count',
  method: 'get',
  params
})

const getOrderStatus = (params) => axios({
  url: '/dashboard/order-status',
  method: 'get',
  params
})

const getMostSoldItems = (params) => axios({
  url: '/dashboard/most-sold',
  method: 'get',
  params
})


export default {
  getRevenues, getOrderCount, getOrderStatus,
  getTotalRevenue, getTotalOrder, getTotalProduct, getTotalUser,
  getMostSoldItems
}