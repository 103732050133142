import React from 'react'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export const ViewBtn = ({ handleClick }) => {
  return (
    <div className='border-2 rounded-lg text-white bg-slate-400 px-3 py-2 hover:bg-slate-600'
      onClick={handleClick}>
      <FontAwesomeIcon icon={faEye} />
    </div>
  )
}
