import { useState } from "react"
import { useDispatch } from "react-redux"
import { Button, Carosel } from "../components"
import { closeModal, showModal } from '../store/modalSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { faX } from "@fortawesome/free-solid-svg-icons";


export const ProductImgGallery = ({ images = [''] }) => {
  const dispatch = useDispatch()
  const [currentImg, setCurrentImg] = useState(images[0])
  const [offset, setOffset] = useState(0)

  return (
    <div className='w-[80%]'>
      <div className='absolute right-12'>
        <Button handleClick={() => dispatch(closeModal())}><FontAwesomeIcon icon={faX} /></Button>
      </div>

      <img src={currentImg} className='w-[650px] h-[550px] m-auto'></img>
      <div className='mt-6 relative'>
        {offset > 0 && (<button onClick={() => {
          if (offset == 0) return
          setOffset(prev => --prev)
        }} className='bg-slate-100 mr-8 absolute top-1/3 text-3xl w-[40px] h-[40px] text-slate-500 z-10 rounded-lg'><FontAwesomeIcon icon={faAngleLeft} /></button>
        )}

        {
          (images?.length !== 0) && (<div className='flex gap-2 w-full overflow-hidden'>
            {images?.slice(offset, offset + 7).map((el, idx) => (
              <>
                <img src={el} className={`w-[80px] h-[80px] cursor-pointer hover:border-2 rounded-lg border-primary ${currentImg == el && 'border-4 border-tertiary'}`} onClick={() => { setCurrentImg(el) }} key={idx} />
              </>
            ))}
          </div>)
        }

        {offset + 7 < images.length && (
          <button onClick={() => {
            // if (offset + 7 >= images.length) return
            setOffset(prev => ++prev)
          }} className='bg-slate-100 mr-8 absolute top-1/3 text-3xl w-[40px] h-[40px] text-slate-500 right-0 z-10 rounded-lg'><FontAwesomeIcon icon={faAngleRight} /></button>
        )}



      </div>


    </div>

  )
}
