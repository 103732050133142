import React, { useState, useEffect } from 'react'
import API from '../apis/index'
import { Button, Input, ViewBtn } from '../components'
import './Dashboard.css'
import moment from 'moment'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar, PieChart, Pie, Cell, ResponsiveContainer
} from "recharts";
import { formatMoney } from '../utils/utils'
import { Pagination } from '@mui/material'
import { mapWord } from '../utils/utils'
import { useNavigate } from 'react-router-dom'

const RevenueLineChart = ({ from, to }) => {
  const [data, setData] = useState([])

  const fetchData = async () => {
    const res = await API.DashBoardAPI.getRevenues({ from, to })
    setData(res)
  }

  useEffect(() => {
    fetchData()
  }, [from, to])

  return (
    <div className='w-[1200px] border-2 py-6 px-16 bg-white shadow-lg'>
      <div className='text-xl text-center w-full mb-2'>Doanh thu</div>
      {(data.length == 0) ? (<div className='h-[100px] flex items-center justify-center border'>Không có dữ liệu</div>) : (<LineChart
        width={1000}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis tickFormatter={(value) => {
          return formatMoney(value / 1000000)
        }}
          label={{ value: 'Doanh thu (*triệu)', angle: -90, position: 'insideLeft', offset: -15 }}
        />


        <Tooltip />
        <Line type="monotone" dataKey="revenue" stroke="#82ca9d" />
      </LineChart>)}
    </div>
  )
}

const OrderCountBarChart = ({ from, to }) => {
  const [data, setData] = useState([])

  const fetchData = async () => {
    const res = await API.DashBoardAPI.getOrderCount({ from, to })
    setData(res)
  }

  useEffect(() => {
    fetchData()
  }, [from, to])

  return (
    <div className='w-[800px] border-2 py-6 px-8 bg-white shadow-lg'>
      <div className='text-xl text-center w-full mb-2'>Tổng đơn</div>
      {(data.length == 0) ? (<div className='h-[100px] flex items-center justify-center border'>Không có dữ liệu</div>)
        : (<BarChart width={700} height={300} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis
            label={{ value: 'Tổng đơn', angle: -90, position: 'insideLeft', offset: 10 }}
          />
          <Tooltip />
          <Bar dataKey="totalCount" fill="#8884d8" />
        </BarChart>)}

    </div>
  )
}

const OrderStatusPieChart = ({ from, to }) => {
  const [data, setData] = useState([])


  const COLORS = {
    "pending": "#0088FE",
    "preparing": "#00C49F",
    "ready-to-ship": "#FFBB28",
    "shipping": "#FF8042",
    "success": "#8884d8",
    "canceled": "#808080"
  };

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };


  const renderColorfulLegendText = (value, entry) => {

    console.log('entry: ', entry)
    const { status, totalCount } = entry.payload.payload.payload
    return (
      <span style={{ color: "#596579", fontWeight: 400, padding: "10px" }}>
        {mapWord(status)}
      </span>
    );
  };

  const fetchData = async () => {
    const res = await API.DashBoardAPI.getOrderStatus({ from, to })
    setData(res)
  }

  useEffect(() => {
    fetchData()
  }, [from, to])

  return (
    <div className='w-[380px] border-2 py-6 px-8 bg-white shadow-lg'>
      <div className='text-xl text-center w-full mb-4'>Trạng thái đơn hiện tại</div>
      {(data.length == 0) ? (<div className='h-[100px] flex items-center justify-center border'>Không có dữ liệu</div>)
        : (<PieChart width={380} height={330}>
          <Pie
            data={data}
            cx="40%"
            cy="40%"
            labelLine={false}
            label={renderCustomizedLabel}
            fill="#8884d8"
            dataKey="totalCount"
            innerRadius={50}
            outerRadius={100}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[entry.status]} />
            ))}
          </Pie>
          <Legend formatter={renderColorfulLegendText} layout="horizontal" align="bottom" verticalAlign="bottom" />
          {/* <Legend
          height={36}
          iconType="circle"
          layout="vertical"
          verticalAlign="middle"
          iconSize={10}
          padding={5}
          formatter={renderColorfulLegendText}
        /> */}
        </PieChart>)}
    </div>
  )
}

const FigureBox = ({ title = 'Title', type = "revenue", from, to }) => {
  const [value, setValue] = useState(0)

  const fetchFigure = async () => {
    let res = null
    switch (type) {
      case "revenue":
        res = await API.DashBoardAPI.getTotalRevenue({ from, to })
        console.log('revenue: ', res)
        setValue(formatMoney(res.revenue) || 0)
        break
      case "order":
        res = await API.DashBoardAPI.getTotalOrder({ from, to })
        setValue(res.totalCount || 0)
        break
      case "product":
        res = await API.DashBoardAPI.getTotalProduct({ from, to })
        setValue(res.totalCount || 0)
        break
      case "user":
        res = await API.DashBoardAPI.getTotalUser({ from, to })
        setValue(res.totalCount || 0)
        break
    }
  }


  useEffect(() => {
    fetchFigure()
  }, [from, to])


  return (
    <div className='w-[250px] h-[160px] border-2 shadow-md rounded-xl p-4'>
      <div className='text-center text-xl'>{title}</div>
      <div className='text-center text-3xl mt-6'>{value}</div>
    </div>
  )
}

const MostSoldItemsTable = ({ from, to }) => {

  const [popularProducts, setPopularProducts] = useState([])
  const [page, setPage] = useState(1)
  const LIMIT = 5

  const navigate = useNavigate()


  const fetchPopularProducts = async () => {
    const products = await API.DashBoardAPI.getMostSoldItems({ from, to, page, limit: LIMIT })
    console.log('fetch product: ', products)
    setPopularProducts(products)
  }


  useEffect(() => {
    fetchPopularProducts()
  }, [from, to, page])

  return (
    <div className='border-2 shadow-lg p-8 w-[1200px]'>
      <div className='text-xl font-semibold mb-4'>Sản phẩm bán chạy</div>

      <table>
        <thead>
          <tr>
            <th>Hình ảnh</th>
            <th>Tên sản phẩm</th>
            <th>Danh mục</th>
            <th>Thương hiệu</th>
            <th>Giá</th>
            <th>Đã bán</th>
            <th>Hành động</th>
          </tr>
        </thead>
        <tbody>
          {popularProducts.map((product, idx) => {
            return (
              <tr className='hover:bg-slate-200 cursor-pointer' key={idx}>
                <td><img src={product.img} style={{ maxWidth: '60px', maxHeight: '60px', margin: 'auto' }} /></td>
                <td className='text-left px-8'>
                  <div className='max-w-[300px] h-[30px] overflow-hidden'>{product.title}</div>
                  <div className='text-xs text-primary font-semibold'>SKU: {1234}</div>
                </td>
                <td>{product.category}</td>
                <td>{product.brand}</td>
                <td>{formatMoney(product.price)}</td>
                <td>{product.totalSold}</td>
                <td className='flex justify-center border-none pt-3 items-center'>
                  <ViewBtn handleClick={

                    () => {
                      navigate(`/product/${product._id}`)
                    }
                  } />

                </td>
              </tr>
            )
          }
          )}
        </tbody>
      </table>
      <div className='w-fit mt-8 alert m-auto'>
        <Pagination count={5} color="primary" onChange={(event, value) => { setPage(value) }} />
      </div>
    </div>
  )
}

export const Dashboard = () => {
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')

  return (<>
    <div className='text-3xl mb-2 px-10'>Dashboard</div>
    {/* <div className='bg-slate-200 px-6 py-1 w-fit mb-6 float-right flex gap-4 rounded-lg'>
      <span className='cursor-pointer hover:bg-slate-300 px-2'>Ngày</span>
      <span className='cursor-pointer hover:bg-slate-300 px-2'>Tuần</span>
      <span className='cursor-pointer hover:bg-slate-300 px-2'>Năm</span>
    </div> */}
    <div className='flex float-right mb-10 mr-6'>
      <div>
        <div>Từ</div>
        <Input type='date' value={from} setValue={setFrom} />
      </div>
      <div>
        <div>Đến</div>
        <Input type='date' value={to} setValue={setTo} max={moment().format('DD-MM-YYYY')} />
      </div>
    </div>
    <div className='clear-right'></div>

    {/* <div className='flex gap-12'>
      <Chart id={'651556b5-4605-440d-898a-703471197735'} style='number-container' />
      <Chart id={'650f0b33-0463-4cc1-848d-80be0a2f05a5'} style='number-container' />
      <Chart id={'65155e51-fce4-403a-88ab-220453cc00ef'} style='number-container' />
      <Chart id={'650f0b33-0463-4cc1-848d-80be0a2f05a5'} style='number-container' />
    </div> */}

    <div className='flex flex-col items-center'>
      <div className='flex gap-12 mb-16'>
        <FigureBox title={'Doanh thu'} value={''} type='revenue' from={from} to={to} />
        <FigureBox title={'Tổng đơn hàng'} value={''} type='order' from={from} to={to} />
        <FigureBox title={'Sản phẩm đã bán'} value={''} type='product' from={from} to={to} />
        <FigureBox title={'Người dùng mới'} value={''} type='user' from={from} to={to} />
      </div>

      <RevenueLineChart from={from} to={to}></RevenueLineChart>

      <div className='flex gap-6 mt-10'>
        <OrderCountBarChart from={from} to={to}></OrderCountBarChart>
        <OrderStatusPieChart from={from} to={to}></OrderStatusPieChart>
      </div>


      <div className='mt-10'>
        <MostSoldItemsTable from={from} to={to} />
      </div>
    </div>

    {/* <div className='border shadow-md p-8 w-[600px] mt-10'>
      <div className='text-xl font-semibold mb-4'>Sản phẩm nổi bật</div>
      <ul>
        {
          popularProducts.map((item, idx) => (
            <li key={idx}>
              <div className='flex gap-4'>
                <img src={item.img} className='w-[60px] h-[60px] rounded-lg border-2 shadow-md'></img>
                <div>
                  <div className='font-semibold'>{item.title}</div>
                  <div>Đã bán: {item.totalSold}</div>
                </div>
              </div>
              <hr className='my-3'></hr>
            </li>
          ))
        }


      </ul>
    </div> */}



    {/* 
    <div className='flex flex-col items-center'>
      <div className='flex gap-12'>
        <Chart id={'651556b5-4605-440d-898a-703471197735'} style='number-container' />
        <Chart id={'650f0b33-0463-4cc1-848d-80be0a2f05a5'} style='number-container' />
        <Chart id={'65155e51-fce4-403a-88ab-220453cc00ef'} style='number-container' />
        <Chart id={'650f0b33-0463-4cc1-848d-80be0a2f05a5'} style='number-container' />
      </div>

      <div className='flex gap-8 mt-8'>
        <Chart id={'650ebc79-a316-4016-89fd-bd1d3a8b9a05'} style='shadow-md border w-[740px] h-[380px]' />
        <Chart id={'650f10ba-0463-451d-85b7-80be0a3610f0'} style='shadow-md border w-[320px] h-[300px]' />
      </div >

      <div className='flex gap-8 mt-8'>
        <Chart id={'650f1048-bf69-450f-84e5-b0ca0629a0f0'} style='shadow-md border w-[740px] h-[340px] m-auto' />
        <div className='relative -top-20 border shadow-md p-8 w-[320px]'>
          <div className='text-xl font-semibold mb-4'>Sản phẩm nổi bật</div>
          <ul>
            {
              popularProducts.map((item, idx) => (
                <li key={idx}>
                  <div className='flex gap-4'>
                    <img src={item.img} className='w-[60px] h-[60px] rounded-lg border-2 shadow-md'></img>
                    <div>
                      <div className='font-semibold'>{item.title}</div>
                      <div>Đã bán: {item.totalSold}</div>
                    </div>
                  </div>
                  <hr className='my-3'></hr>
                </li>
              ))
            }


          </ul>
        </div>

      </div>
    </div> */}



  </>

  )
}
