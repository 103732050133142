import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import {
  Discount, Order, User, Category, Product, Dashboard, AddProduct,
  OrderDetail,
  ProductDetail,
  Chat,
  AddCategory,
  Signin
} from './pages';

import { store } from './store/store'
import { Provider } from 'react-redux'

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [{
      index: true,
      element: <Dashboard />,
    },

    {
      path: "/products",
      element: <Product />,
    },
    {
      path: "/product/:pid",
      element: <ProductDetail />,
    },
    {
      path: "/add_product",
      element: <AddProduct />,
    },
    {
      path: "/categories",
      element: <Category />,
    },
    {
      path: "/add_category",
      element: <AddCategory />,
    },
    {
      path: "/orders",
      element: <Order />,
    },
    {
      path: "/order/:id",
      element: <OrderDetail />,
    },
    {
      path: "/discounts",
      element: <Discount />,
    },
    {
      path: "/users",
      element: <User />,
    },
    {
      path: "/chat",
      element: <Chat />,
    }]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);
