import React from 'react'
import './RangeInput.css'
import Slider from '@mui/material/Slider';

function valuetext(value) {
  return `${value}°C`;
}

const RangeInput = () => {
  const [value, setValue] = React.useState([20, 37]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <div className='flex'>
        <div className='p-4 border-2 w-[100px]'>{value[0]}</div>
        <div className='p-4 border-2 w-[100px]'>{value[1]}</div></div>
      <div>

        <div className='range-slider'>
          {/* <div className='bg-gray-600 w-[100px]'></div>
          <div className='w-[18px] h-[18px] rounded-full border border-tertiary'>abc</div>
          <div className='w-[18px] h-[18px] rounded-full border border-tertiary'>abc</div> */}
          <Slider
            getAriaLabel={() => 'Temperature range'}
            value={value}
            onChange={handleChange}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
          />
        </div>
      </div>
    </div >
  )
}

export default RangeInput