import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  withCredentials: true
})

const getAccessToken = () => {
  return localStorage.getItem('access_token');
};

const getRefreshToken = () => {
  return localStorage.getItem('refresh_token');
};

const getUid = () => {
  return localStorage.getItem('uid');
};

const setAccessToken = (token) => {
  return localStorage.setItem('access_token', token);
}

const refreshAccess = async () => {
  console.log('refresh access')
  try {
    // Make a request to your server to refresh the token
    const refreshToken = getRefreshToken()
    const uid = getUid()
    if (refreshToken) {
      const response = await instance.post('/user/refresh-access', { refreshToken, uid });
      // Retry the original request
      return response

    }
  } catch (error) {
    // Handle refresh token failure (e.g., redirect to login page)
    console.error('Error refreshing token:', error);
    throw error;
  }
};



// Add a request interceptor
instance.interceptors.request.use(function (config) {
  // Do something before request is sent

  const accessToken = getAccessToken();

  // If the access token is present, set it in the Authorization header
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }


  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data

  return response.data;
}, async function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error


  console.log('error message: ', error.response.data.message)

  //request access token
  if (error.response.data.message == 'jwt expired') {
    const access = await refreshAccess()
    try {
      if (access.success) {
        console.log('new access token: ', access.token)
        setAccessToken(access.token)
        // error.config.headers['Authorization'] = `Bearer ${access.token}`
        // Update the original request's headers with the new access token
        instance.defaults.headers.common['Authorization'] = `Bearer ${access.token}`;
        error.config.headers['Authorization'] = `Bearer ${access.token}`;
        return instance.request(error.config)
      }
      else {
        console.log('unvalid refresh token')
        return Promise.reject(error);

      }
    }
    catch (err) {
      return Promise.reject(error);
    }
  }

  return Promise.reject(error);
});

export default instance
