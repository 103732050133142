import CategoryAPI from './Category'
import ProductAPI from './Product'
import UserAPI from './User'
import DiscountAPI from './Discount'
import OrderAPI from './Order'
import ChatAPI from './Chat'
import DashBoardAPI from './DashBoard'


const API = {
  CategoryAPI, ProductAPI, UserAPI, DiscountAPI,
  OrderAPI, ChatAPI, DashBoardAPI
}

export default API
