import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button, AddBtn, EditBtn, DeleteBtn, Input } from '../components';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, showModal } from '../store/modalSlice';
import { Carosel } from '../components/Carosel';
import Editor from '../components/Editor';
import Swal from 'sweetalert2';
import { extractAttributeValues, formatMoney } from '../utils/utils';
import API from '../apis';
import { UploadWidget, ProductImgGallery } from '../components'
import { private_excludeVariablesFromRoot } from '@mui/material';
import { HtmlParser } from '../utils/utils';

const FilterModal = ({ category, productId, variantId }) => {
  const categories = useSelector(state => state.category.value)
  const [filters, setFilters] = useState([])
  const [filterValues, setFilterValues] = useState([])

  const handleUpdateFilter = async () => {
    const res = await API.ProductAPI.updateFilters(
      { category, productId, variantId, filters }
    )

    console.log('result update filter: ', res)

    Swal.fire({
      title: 'Cập nhật thông tin sản phẩm?',
      showCancelButton: true,
      confirmButtonColor: '#2E8BC0',
      confirmButtonText: 'Cập nhật',
      cancelButtonText: `Hủy`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Cập nhật thành công!', '', 'success')
        dispatch(closeModal())
      }
    })

  }


  // useEffect(() => {
  //   const find_category = categories.find(item => item.name == category)
  //   let filter_category = []
  //   find_category.filters.forEach(item => {
  //     filter_category.push({ name: item, value: '' })
  //   })

  //   setFilters(filter_category)

  // }, [])

  const fetchFilter = async () => {
    const filterValues = await API.ProductAPI.getFilter({ productId, variantId })

    if (!!filterValues) { setFilters(filterValues.filters) }
    else {
      const categoryFilterValues = categories.find(item => item.name == category)
      const filterItems = categoryFilterValues.filters.map(item => ({
        name: item,
        value: ''
      }))

      setFilters(filterItems)
    }




  }

  useEffect(() => {
    fetchFilter()
  }, [])

  const dispatch = useDispatch()

  return (
    <div className='bg-white w-[50%] h-[75%] py-10 px-20 rounded-lg overflow-y-scroll'>

      <div className='font-semibold text-2xl mb-6 text-center'>Filter</div>

      <div>
        {filters?.map((item, idx) => (
          <div className='flex gap-6 mb-3' key={idx}>
            <div className='w-[150px]'>{item.name}</div>
            {/* <select>
            {item.values?.map((filter_value, idx) => (
              <option key={idx} value='filter_value'>{filter_value}</option>
            ))}
          </select> */}

            <Input width={250} value={item.value} setValue={(value) => {
              let update_filter = [...filters]
              update_filter[idx].value = value
              setFilters(update_filter)
              console.log('update filter: ', filters)
            }
            } />
            {/* 
            <input type='text' value={item.value} onChange={e => {
              let update_filter = [...filters]
              update_filter[idx].value = e.target.value
              setFilters(update_filter)
              console.log('update filter: ', filters)
            }} ></input> */}
          </div>
        ))}
      </div>

      <div className='flex justify-center gap-4 mt-6'>
        <Button handleClick={handleUpdateFilter}>Lưu</Button>
        <Button style={'px-4 py-2 rounded-md text-white bg-red-600 text-semibold my-2'} handleClick={() => {
          dispatch(closeModal())
        }}>Hủy</Button>      </div>
    </div>
  )
}

const VariantModal = ({ variant, product, setProduct }) => {
  const dispatch = useDispatch()
  const [price, setPrice] = useState(variant.price)
  const [quantity, setQuantity] = useState(variant.stock)
  const [changeQuantityAmount, setChangeQuantityAmount] = useState(0)
  const handleChangeQuantity = (flag) => {
    if (flag == '-') {
      setQuantity(parseInt(quantity) - parseInt(changeQuantityAmount))
    }
    else if (flag == '+') {
      console.log('quantity: ', quantity)
      setQuantity(parseInt(quantity) + parseInt(changeQuantityAmount))
    }
    setChangeQuantityAmount(0)
  }

  const handleUpDateVariant = () => {
    const variants = [...product.variants]
    const idx = variants.findIndex(item => item._id == variant._id)
    variants[idx].price = price
    variants[idx].stock = quantity
    setProduct(prev => ({ ...prev, variants }))
    dispatch(closeModal())
  }



  useEffect(() => {
    console.log('variant: ', variant)
  }, [])

  return (
    <div className='bg-white w-[50%] h-[70%] p-10 rounded-lg'>
      <div className='font-semibold text-2xl mb-6 text-center'>Biến thể</div>
      <div>
        <div>
          <div className='mb-2 font-semibold'>Thuộc tính</div>
          <div>
            <table>
              <thead>
                <tr>
                  <th>Thuộc tính</th>
                  <th>Giá trị</th>
                </tr>
              </thead>
              <tbody>
                {variant.attributes.map((item, idx) => (
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className='flex gap-4 mt-8 mb-6 items-center'>
          <div className='mb-2 font-semibold'>Giá: </div>
          <Input type='number' min='0' value={price} setValue={setPrice} />
        </div>

        <div>
          <div className='flex gap-2 items-center'>
            <div className='font-semibold mb-2'>Tồn kho: </div>
            <div>{quantity}</div>
          </div>
          <div className='mr-10 flex gap-3 mt-2'>
            <div className='border-2 bg-slate-100 rounded py-1 px-2 cursor-pointer hover:bg-slate-200 text-sm'
              onClick={() => { handleChangeQuantity('-') }}
            >Giảm</div>
            <Input type='number' min='0' value={changeQuantityAmount} setValue={setChangeQuantityAmount}></Input>
            <div className='border-2 bg-slate-100 rounded py-1 px-2 cursor-pointer hover:bg-slate-200 text-sm'
              onClick={() => { handleChangeQuantity('+') }}
            >Thêm</div>

          </div>

        </div>

      </div>
      <div className='flex justify-center gap-4 mt-6'>
        <Button handleClick={handleUpDateVariant}>Lưu</Button>
        <Button style={'px-4 py-2 rounded-md text-white bg-red-600 text-semibold my-2'} handleClick={() => {
          dispatch(closeModal())
        }}>Hủy</Button>      </div>

    </div>
  )

}

const AddVariantModal = ({ attributes, handleAddVariant }) => {
  const dispatch = useDispatch()
  const [variant, setVariant] = useState({ attributes: [], price: 0, stock: 0, sold: 0 })
  useEffect(() => {
    let variant_attributes = []
    attributes.forEach(item => {
      variant_attributes.push({ name: item, value: '' })
    })
    setVariant(prev => ({ ...prev, attributes: variant_attributes }))
  }, [])
  return (<div className='bg-white w-[40%] h-[70%] rounded-lg py-6 px-10'>
    <div className='font-semibold text-2xl mb-6 text-center'>Phân loại</div>

    <div className='mb-2'>Thuộc tính: </div>
    <div>
      <table>
        <thead>
          <tr>
            <th>Thuộc tính</th>
            <th>Giá trị</th>
          </tr>
        </thead>
        <tbody>
          {variant.attributes.map((item, idx) => (
            <tr>
              <td>{item.name}</td>
              <td><input className='w-full px-4' onChange={(e) => {
                let attributes = variant.attributes.map(spec => {
                  let value = spec.value
                  if (spec.name == item.name) {
                    value = e.target.value
                  }
                  return {
                    ...spec, value
                  }
                })

                setVariant(prev => ({ ...prev, attributes }))
              }}></input></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <div className='flex mt-6'>
      <span className='w-[15%]'>Giá</span><input type='number' className='input-text' min={0} onChange={
        (e) => {
          setVariant(prev => ({ ...variant, price: +e.target.value }))
        }
      }></input>
    </div>
    <div className='flex mt-4'>
      <span className='w-[15%]'>Số lượng</span><input type='number' className='input-text' min={0} onChange={
        (e) => {
          setVariant(prev => ({ ...variant, stock: +e.target.value }))
        }
      }></input>
    </div>

    <div className='flex justify-center gap-4 mt-6'>
      <Button handleClick={() => {
        handleAddVariant(variant)
        dispatch(closeModal())
        console.log('add variant: ', variant)
      }}>Thêm</Button>

      <Button style={'px-4 py-2 rounded-md text-white bg-red-600 text-semibold my-2'} handleClick={() => {
        dispatch(closeModal())
      }}>Hủy</Button>
    </div>

  </div>)
}

const ProductDetail = () => {
  const [product, setProduct] = useState(null)
  const dispatch = useDispatch()
  const location = useLocation()
  //const data = location.state
  const [variantAttributes, setVariantAttributes] = useState([])


  const categories = useSelector(state => state.category.value)
  const brands = useSelector(state => state.product.brands)
  const [images, setImages] = useState([])

  const navigate = useNavigate()

  const handleUpdateProduct = async () => {

    Swal.fire({
      title: 'Cập nhật thông tin sản phẩm?',
      showCancelButton: true,
      confirmButtonColor: '#2E8BC0',
      confirmButtonText: 'Cập nhật',
      cancelButtonText: `Hủy`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const update_product = await API.ProductAPI.updateProduct(product)
        setProduct(update_product)
        Swal.fire('Cập nhật thành công!', '', 'success')
      }
    })
  }
  const handleDeleteProduct = () => {

    Swal.fire({
      title: 'Có chắc chắn xóa sản phẩm?',
      showCancelButton: true,
      confirmButtonColor: '#2E8BC0',
      confirmButtonText: 'Có',
      cancelButtonText: `Hủy`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await API.ProductAPI.deleteProduct(product)
        Swal.fire('Đã xóa thành công!', '', 'success')
        navigate('/products')
      }
    })

  }

  const handleDeleteVariant = async (variant) => {

    const variants = product.variants.filter(item => item._id !== variant._id)
    // setProduct(prev => ({ ...prev, variants }))

    const update_product = { ...product, variants }
    const res = await API.ProductAPI.updateProduct(update_product)

    console.log('update after add variant: ', res)

    setProduct(res)
  }

  const handleAddVariant = async (variant) => {

    const update_product = { ...product, variants: [...product.variants, variant] }
    const res = await API.ProductAPI.updateProduct(update_product)
    setProduct(res)

  }

  const { pid } = useParams()

  const fetchDetailProduct = async () => {
    const product = await API.ProductAPI.getProduct(pid)
    console.log('fetch product: ', product)
    setProduct(product)
  }

  const handleOnChangeInput = (e) => {
    const { name, value } = e.target
    setProduct(prev => { return { ...prev, [name]: value } })

  }

  useEffect(() => {
    console.log('prouduct id: ', pid)
    fetchDetailProduct()

  }, [pid])

  useEffect(() => {
    console.log('product: ', product)
    const attributes = product?.variants[0].attributes.map(item => item.name)
    console.log('variant attributes: ', attributes)
    setVariantAttributes(attributes)

  }, [product])

  return (
    <>
      {product ? (<div className='w-full'>
        <div className='mb-8'><Link to='/products'><span className='bg-white p-2 px-4 rounded-lg border mr-4 hover:bg-tertiary hover:text-white cursor-pointer'><FontAwesomeIcon icon={faChevronLeft} /></span></Link>
          <span className='font-bold text-xl mb-12'>{product.title}</span>
        </div>
        <div>

          <div className='px-12'>

            <div className='flex items-start items-center'>
              <div className='flex flex-col items-center'>
                <img className='w-[200px] h-[200px] rounded-lg border p-2 shadow-lg' src={product.mainImg ? product.mainImg : 'https://images.unsplash.com/photo-1526170375885-4d8ecf77b99f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80'}></img>
                <UploadWidget btnText='Đổi ảnh chính' isMultiple={false} setImgs={(url) => {

                  setProduct(prev => ({ ...prev, mainImg: url }))
                }} />
              </div>
              <div className='ml-12'>
                <div className='mb-6 w-full flex'>
                  <label className='mr-4 w-[100px] font-semibold'>Tên sản phẩm</label>
                  <input type='text' name='title' className='input-text w-[550px]' value={product.title} onChange={handleOnChangeInput} />
                </div>

                <div className='mb-6 mt-6 w-full flex'>
                  <label className='mr-4 w-[100px] font-semibold'>Thương hiệu</label>
                  <select type='text' className='input-text w-[400px]' name='brand' onChange={handleOnChangeInput}>
                    {brands.map((item, idx) => (
                      <option selected={item == product.brand} key={idx} value={item}>{item}</option>
                    ))}
                  </select>

                </div>

                <div className='mb-6 mt-6 w-full flex'>
                  <label className='mr-4 w-[100px] font-semibold'>Danh mục</label>
                  <select type='text' className='input-text w-[400px]' name='category' onChange={handleOnChangeInput}>
                    {categories.map((item, idx) => (
                      <option selected={item.name == product.category} key={idx} value={item.name}>{item.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div >

            <div className='mt-8'>
              <div className='font-semibold mb-2 text-lg'>Ảnh sản phẩm</div>
              <div>
                <div className='flex gap-4'>
                  {
                    product.images.map((item, idx) => (
                      (idx < 6 && <img key={idx} className='w-[150px] h-[150px] rounded-lg border-2' src={item}></img>
                      )
                    ))
                  }

                  {product.images.length > 5 && (
                    <div className='w-[150px] h-[150px] rounded-lg bg-gray-400 flex justify-center items-center cursor-pointer'
                      onClick={() => dispatch(showModal(<ProductImgGallery images={product.images} />))}>
                      <span className='text-white'>Xem thêm...</span>
                    </div>
                  )}

                </div>
                <div className='ml-6 mt-4'>
                  <UploadWidget isMultiple={true} imgs={images} setImgs={setImages} />
                </div>
              </div>
            </div>

            <div className='mb-6 w-full mt-12'>
              <div className='flex w-[930px] items-center justify-between mb-4'>
                <div className='font-semibold text-lg'>Biến thể</div>
                <div className='mr-10'>
                  <Button style={'border-2 border-primary text-sm text-slate-600 rounded-lg w-fit cursor-pointer p-1 hover:bg-tertiary hover:text-white hover:border-tertiary'}
                    handleClick={() => {
                      dispatch(showModal(<AddVariantModal attributes={variantAttributes} handleAddVariant={handleAddVariant} />))
                    }}>
                    <FontAwesomeIcon icon={faPlus} /><span className='ml-2'>Thêm biến thể</span>
                  </Button>
                </div>
              </div>
              {
                (product.variants.length > 0) && (<table className='w-[900px]'>
                  <thead>
                    <tr>
                      <th>Phân loại</th>
                      <th>Giá bán</th>
                      <th>Tồn kho</th>
                      <th>Đã bán</th>
                      <th>Thao tác</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      product.variants.map((item, idx) => (
                        <tr>
                          <td>{extractAttributeValues(item.attributes) || 'Một phân loại'}</td>
                          <td>{formatMoney(item.price)}</td>
                          <td>{item.stock}</td>
                          <td>{item.sold}</td>
                          <td>
                            <EditBtn handleEdit={() => {
                              dispatch(showModal(<VariantModal variant={item} product={product} setProduct={setProduct} />))
                            }} />

                            {product.variants.length > 1 && (<DeleteBtn handleDelete={() => handleDeleteVariant(item)} />)}
                          </td>

                        </tr>
                      ))
                    }
                  </tbody>

                </table>)
              }
            </div>

            <div className='mb-8 w-full'>
              <div className='font-semibold mb-2 text-lg'>Filter</div>
              {
                (product.variants.length > 0) && (<table className='w-[700px]'>
                  <thead>
                    <tr>
                      <th>Phân loại</th>
                      <th>Filter</th>

                    </tr>
                  </thead>
                  <tbody>
                    {
                      product.variants.map((item, idx) => (
                        <tr>
                          <td>{extractAttributeValues(item.attributes) || 'Một phân loại'}</td>
                          <td><EditBtn handleEdit={
                            () => {

                              dispatch(showModal(<FilterModal category={product.category} productId={product._id} variantId={item._id} />))

                            }
                          }></EditBtn></td>

                        </tr>
                      ))
                    }
                  </tbody>

                </table>)
              }
            </div>



            <div className='mb-8'>
              <label className='mr-4 font-semibold mb-4 text-lg'>Mô tả</label>
              <Editor setContent={(content) => {
                let parser = new HtmlParser()
                console.dir(`new content is updated ${JSON.stringify(parser.parseHTML(content))}`)
                setProduct(prev => ({ ...prev, description: parser.parseHTML(content) }))
              }} />
            </div>

            <div className='mb-8'>
              <div className='mr-4 font-semibold mb-2 text-lg'>Thông số kĩ thuật</div>
              <table>
                <thead>
                  <tr>
                    <th>Thông số</th>
                    <th>Giá trị</th>
                  </tr>
                </thead>
                <tbody>
                  {product.tech_specs.map((item, idx) => (
                    <tr>
                      <td><input type='text' className='w-full' value={item.name} disabled={true} /></td>
                      <td><input type='text' placeholder='giá trị' className='w-full' value={item.value}
                        onChange={(e) => {
                          const tech_specs = [...product.tech_specs]
                          tech_specs[idx].value = e.target.value
                          setProduct(prev => ({ ...prev, tech_specs }))
                        }} /></td>
                    </tr>
                  ))}

                </tbody>
              </table>
            </div>

          </div>


          <div className='flex justify-center items-center gap-8'>
            <Button handleClick={handleUpdateProduct}>Cập nhật sản phẩm</Button>
            <Button handleClick={handleDeleteProduct} style={'block w-[15%] p-2 rounded-md text-white bg-red-600 text-semibold hover:bg-red-700'}>Xóa sản phẩm</Button>
          </div>
        </div >
      </div >) : (<div></div >)
      }
    </>
  )
}

export default ProductDetail