import React from 'react'

const Input = ({ type, width, value = '', setValue, min, max, disabled=false }) => {
  const handleChangeValue = (e) => {
    setValue(e.target.value)
  }
  return (
    <input type={type} id='quantity' className={`border-2 py-1 px-2 rounded-md mr-1 
    ${width ? `w-[${width}px]` : 'w-[150px]'} ${(value !== '' && value !== '0') && 'bg-input-fill'}`} min={min} max={max} value={value}
      onChange={setValue && handleChangeValue}
      disabled={disabled}
      ></input>
  )
}

export default Input