import axios from '../utils/axios'


const getUsers = (params) => axios({
  url: '/user/all',
  method: 'get',
  params
})

const updateUser = (data) => axios({
  url: `/user`,
  method: 'put',
  data
})

const signIn = (data) => axios({
  url: '/user/signin',
  method: 'post',
  data
})


// const getUser = () => axios({
//   url: '/user',
//   method: 'get',
// })

const getUser = (data) => axios({
  url: '/user',
  method: 'post',
  data
})


export default { getUsers, updateUser, signIn, getUser }