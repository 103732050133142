import React, { memo } from 'react'

const Button = ({ children, handleClick, style, fw }) => {
  return (
    <button
      type='button'
      className={style ? style : `px-4 py-2 rounded-md text-white bg-tertiary text-semibold my-2 hover:bg-primary ${fw ? 'w-full' : 'w-fit'}`}
      onClick={() => { handleClick && handleClick() }}
    >
      {children}
    </button>
  )
}

export default memo(Button)